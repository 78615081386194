import { Typography } from '@material-ui/core';
import { ENUMS_ERROR_ACC_CODE } from 'constants/Enums';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import styles from './styles.module.css';

function DeleteAccountMessage({className, translateMyAccount, ...props}) {
    const {code}= props;

    const message = useMemo(() => { 
      switch(code) {
        case ENUMS_ERROR_ACC_CODE.CUSTOMER_DELETED_NO_ORDER_60_DAYS:
          return translateMyAccount('customer_delete_because_no_order_60_days');
        case ENUMS_ERROR_ACC_CODE.CUSTOMER_DELETED_NO_ORDER_90_DAYS:
          return translateMyAccount('customer_delete_because_no_order_90_days');
        default:  
          return '';
      }
    }, [code]);

    if (!message) {
      return null;
    }

    return (
      <Typography className={styles.text_warning}>{message}</Typography>
    );
}

export default DeleteAccountMessage;
