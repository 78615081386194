import { ValidateUtils } from 'utils';

const validateForm = ({ businessName = '', mst = '', taxCode = '', email = '', address = '', provinceCode, districtCode, wardCode }) => {
  if (ValidateUtils.isEmpty(businessName)) throw Error('message_clinic_name_cannot_be_empty');
  if (ValidateUtils.isEmpty(mst || taxCode)) throw Error('message_empty_tax_code');
  if (ValidateUtils.isEmpty(email)) throw Error('message_cannot_be_empty');
  if (ValidateUtils.isEmpty(address)) throw Error('message_empty_address');
  if (provinceCode === '0') throw Error('message_empty_province_city');
  if (districtCode === '0') throw Error('message_empty_district');
  if (wardCode === '0') throw Error('message_empty_ward');

  if (!ValidateUtils.validateEmail(email)) throw Error('message_invalid_email');
  if (!ValidateUtils.isNumber(mst || taxCode)) throw Error('message_tax_code_wrong_format');
};

export default validateForm;
