import { Grid, InputAdornment, MenuItem, Select } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { Input } from 'components/atoms';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import clsx from 'clsx';
import styles from './styles.module.css';

const IngredientInput = ({ className, ...rest }) => <Input className={className} {...rest} />;

const StyledIngredientInput = styled(IngredientInput)`
  cursor: pointer;
  height: 40px !important;
  background: #ffffff;
  color: #606060;
  z-index: 1;
  border-radius: 8px 0px 0px 8px;
  margin: 0;
  border-right: 1.2px solid #e8e9f8;
  &.Mui-focused {
    z-index: 4;
    border-right: 1.2px solid #e8e9f8 !important;
    border-color: transparent;
  }
`;

const StyledMedicineTypeSelect = styled(Select)`
  cursor: pointer;
  height: 40px !important;
  background: #ffffff;
  color: #606060;
  z-index: 1;
  border-radius: 0px 8px 8px 0px;
  margin: 0;
  width: 100%;
  border: none;
  & .MuiSelect-select {
    padding-left: 5px;
  }
`;

const CloseButton = ({ handleClose }) => <CloseIcon onClick={handleClose} />;
const FloatSearch = ({ value, handleChangeValue, handleClose, placeholder, isMobile }) => {
  const { t: translateIngredients } = useTranslation('ingredients');
  const router = useRouter();
  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      event.target.blur();
    }
  };
  const medicineTypes = [
    {
      label: translateIngredients('medicine'),
      value: 'medicine',
    },
    {
      label: translateIngredients('non_medicine'),
      value: 'notmedicine',
    },
  ];
  const handleChangeType = (e) => {
    router.push(`${router.pathname}?type=${e.target.value}`);
  };
  return (
    <Grid className={clsx(styles.searchBox, isMobile && styles.mobileSearchBox)} container alignItems="center" justifyContent="center">
      <Grid item xs={9}>
        <StyledIngredientInput
          fullWidth
          placeholder={placeholder}
          value={value}
          onChange={handleChangeValue}
          onKeyPress={handleKeyPress}
          endAdornment={value ? <CloseButton handleClose={handleClose} /> : null}
          startAdornment={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          className={styles.searchInput}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledMedicineTypeSelect
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={router?.query?.type || medicineTypes[0].value}
          onChange={handleChangeType}
          disableUnderline
        >
          {medicineTypes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </StyledMedicineTypeSelect>
      </Grid>
    </Grid>
  );
};

export default FloatSearch;
