import { ValidateUtils } from 'utils';

const validateForm = ({ companyName, taxCode, email, companyAddress }) => {
  if (ValidateUtils.isEmpty(companyName.trim())) throw Error('message_must_enter_company_name_invoice_document');
  else if (companyName.length < 2) throw Error('message_company_at_least_2characters');
  else if (ValidateUtils.isEmpty(taxCode)) throw Error('message_must_enter_the_tax_code_invoice_document');
  else if (!ValidateUtils.isEmpty(email) && !ValidateUtils.validateEmail(email)) throw Error('message_invalid_email');
  else if (ValidateUtils.isEmpty(companyAddress.trim())) throw Error('message_you_must_enter_your_address_invoice document');
  else if (!ValidateUtils.isNumber(taxCode) || !(taxCode.length === 10 || taxCode.length === 14)) throw Error('message_tax_code_wrong_format');
};

export default validateForm;
