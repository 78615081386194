import { ListItemIcon, MenuItem, MenuList, Typography } from '@material-ui/core';
import { LinkComp } from 'components/atoms';
import { BILL, FEEDBACK, ORDER, POINTS, QR, REFER, STATISTICAL, USER, VIEWED, VOUCHER, WISHLIST } from 'constants/Icons';

import { useAuth } from 'context';
import { useTranslation } from 'next-i18next';
import { ENV_COUNTRY } from 'sysconfig';
import { gtag } from 'utils';
import { v4 as uuidv4 } from 'uuid';
import styles from './styles.module.css';

const InfoTabs = ({ value, name }) => {
  const { t: translateCommon } = useTranslation('common');
  const tabs = [
    { label: translateCommon('tab_menu.my_account'), icon: <USER />, id: 1, link: '/my-account', enable: true },
    // { label: 'Thông tin cấp bậc', icon: <LEVEL />, id: 2, link: '/user/level', enable: FEATURE_LEVEL },
    { label: translateCommon('tab_menu.my_points'), icon: <POINTS />, id: 8, link: '/users/loyalty_points', enable: true },
    { label: translateCommon('tab_menu.my_orders'), icon: <ORDER />, id: 3, link: '/my-order', enable: true },
    { label: translateCommon('tab_menu.statistics'), icon: <STATISTICAL />, id: 4, link: '/user/dashboard', enable: true },
    { label: translateCommon('tab_menu.refer_friends'), icon: <REFER />, id: 5, link: '/users/referrals', enable: true },
    {
      label: translateCommon('tab_menu.my_discount'),
      icon: <VOUCHER />,
      id: 6,
      link: '/users/user-promo-codes',
      enable: true,
    },
    { label: translateCommon('tab_menu.my_feedback'), icon: <FEEDBACK />, id: 7, link: '/users/my-ticket', enable: true },
    {
      label: translateCommon('tab_menu.bill_of_lading_lookup'),
      icon: <BILL />,
      id: 9,
      link: 'https://tracuu.thuocsi.vn',
      enable: ENV_COUNTRY === 'vn',
    },
    { label: translateCommon('tab_menu.qr_code_lookup'), icon: <QR />, id: 10, link: '/qr', enable: true },
    { label: translateCommon('interested_products'), icon: <WISHLIST />, id: 11, link: '/user/wishlist', enable: true },
    { label: translateCommon('viewed_products'), icon: <VIEWED />, id: 12, link: '/productviewed', enable: true },
  ];

  const { user } = useAuth();
  const { level = '' } = user || {};
  return (
    <div className={styles.tab_box}>
      <div className={styles.account_name}>
        {translateCommon('tab_menu.account_of')}
        <Typography variant="h5" className={styles.name}>
          {name}
        </Typography>
      </div>
      <MenuList>
        {tabs
          ?.filter((item) => item.enable)
          ?.map(
            (tab) =>
              (level !== 'LEVEL_BLACKLIST' || tab.id !== 2) && (
                <LinkComp href={tab.link} key={`tab-${uuidv4()}`} prefetch={false}>
                  <MenuItem
                    button={false}
                    classes={{ root: value === tab.id ? styles.tab_active : styles.tab_inactive }}
                    value={tab.id}
                    onClick={() => {
                      if (tab?.link === 'https://tracuu.thuocsi.vn') {
                        gtag.clickTrackingOrder();
                      }
                    }}
                  >
                    <ListItemIcon>{tab.icon}</ListItemIcon>
                    <Typography variant="inherit">{tab.label}</Typography>
                  </MenuItem>
                </LinkComp>
              ),
          )}
      </MenuList>
      {/* <Typography>Công nợ - &nbsp; {formatCurrency(balance)}</Typography> */}
    </div>
  );
};

export default InfoTabs;
