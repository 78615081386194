import { Box, Button, CircularProgress, Grid, LinearProgress, Modal, NoSsr, Tooltip, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { linearProgressClasses, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

// import { getData, getFirst, isValid } from 'clients/Clients';
import PromoClient, {
  getLogSelfLuckyWheel,
  getMissionSelfLuckyWheel,
  getSelfLuckyWheelRewards,
  postGamificationScore,
  postSpinLuckyWheel,
} from 'clients/PromoClient';
import clsx from 'clsx';
import {
  LUCKWHEEL_ICON_WARNING,
  LUCKYWHEEL_ARROW_SELECTOR,
  LUCKYWHEEL_BACKGROUND,
  LUCKYWHEEL_BLOCKED_ICON,
  LUCKYWHEEL_BORDER_WITH_CENTER,
  LUCKYWHEEL_CENTER,
  LUCKYWHEEL_GUILDLINE_ICON,
  LUCKYWHEEL_MISSION_ICON,
  LUCKYWHEEL_MOBILE_BACKGROUND,
  LUCKYWHEEL_SHARE_ICON,
  LUCKYWHEEL_TIME_ICON,
  LUCKYWHEEL_TITLE
} from 'constants/Images';
import { useAuth } from 'context/Auth';
import { format } from 'date-fns';
import NextImage from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { formatNumber } from 'utils/FormatNumber';
import { ImageFallbackStatic } from 'utils/ImageFallback';
import { getLinkProxyCDN } from 'utils/ImageUtils';
import NotifyUtils from 'utils/NotifyUtils';
// import PopupSharing from '../PopupSharing';
import { getData, getFirst, isValid } from 'clients';
import PopupSharing from 'components-v3/mocules/PopupSharing';
import events from 'events';
import { useTranslation } from 'next-i18next';
import { CURRENCY_SEPARATE, WEB_HOST } from 'sysconfig';
import { convertLuckyMessage, convertMissionType, LUCKY_NEXT_TIME, WRONG_ANSWER, YOU_SPUN_AND_HIT } from 'utils/luckyWheel';
import { isEmpty } from 'utils/ValidateUtils';
import ArrowAnimation from './ArrowAnimation';
import { CONDITION_TYPE, HISTORY_BOARD_TAB } from './constants';
import styles from './styles.module.css';

export const spinContainer = new events.EventEmitter();
function getRandomInt(min, max) {
  return Math.random() * (max - min) + min;
}

// const DEG_TO_RAD = Math.PI / 180;
const RAD_TO_DEG = 180 / Math.PI;
const PAGE_DEFAULT = 1;

const LoaderContainer = (
  <div className={clsx(styles.historyReward_loading)}>
    <CircularProgress size="32px" color="primary" />
  </div>
);

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: theme.palette.mode === 'light' ? '#F29763' : '#F29763',
  },
}));

const defaultvalueSpinnerLog = {
  data: [],
  total: 0,
};

const LuckyWheelPrizeTooltip = styled(({ className, ...props }) => (
  // eslint-disable-next-line react/no-children-prop
  <Tooltip classes={{ popper: className }} children={props.children} title={props.title} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 4px 4px 0px #0000001F;',
  },
}));

const drawReward = (ctx, PI, sweep, img, itemW, itemH, itemX, itemY, text, cx, cy, radius, angle, arcsweep, color) => {
  ctx.save();
  // Reward
  ctx.translate(cx * 2, cy * 2);
  ctx.rotate(angle);
  ctx.beginPath();
  // Stroke
  ctx.lineWidth = 5;
  ctx.lineJoin = 'round';
  ctx.lineCap = 'round';
  ctx.lineTo(0, 0);
  ctx.arc(0, 0, radius, 0, arcsweep);
  ctx.closePath();
  ctx.strokeStyle = 'transparent';
  ctx.stroke();
  // Background
  ctx.fillStyle = color ?? 'transparent'; // color bg
  ctx.fill();
  // Item
  ctx.rotate(PI / 2 + sweep / 2);
  ctx.drawImage(img, itemX, itemY, itemW, itemH);
  // restore the context to its original state
  ctx.restore();
};

const LuckyWheel = ({ isMobile, source, propWheelCode }) => {
  const router = useRouter();
  const { t } = useTranslation('lucky-wheel');
  const { user } = useAuth();
  const { accountID, customerID } = user || {};
  const [spinnerInfo, setSpinnerInfo] = useState({
    rewards: [],
    luckyWheelCode: '',
    radius: 340, // PIXEL
    rotate: 0, // DEGREES
    easeOut: 0, // SECONDS
    angle: 0, // RADIANSs
    top: null, // INDEX
    offset: null, // RADIANS
    net: null, // RADIANS
    result: null, // INDEX
    spinning: false,
    isStop: true, // is RotateStop ?
    turns: 0,
    description: '',
    isAcceptFreeTurn: false,
    nextFreeTime: '',
    backgroundWeb: isMobile ? LUCKYWHEEL_MOBILE_BACKGROUND : LUCKYWHEEL_BACKGROUND,
    imageTitleWeb: LUCKYWHEEL_TITLE,
  });

  const [spinnerLog, setSpinnerLog] = useState(defaultvalueSpinnerLog);
  const [historyBoardTab, setHistoryBoardTab] = useState(HISTORY_BOARD_TAB.MISSION);
  const [rewardIdxMap, setRewardIdxMap] = useState();
  const [isOpenPopup, setOpenPopup] = useState({
    outOfTurn: false,
    outOfReward: false,
    notFound: false,
    gotPrize: false,
  });
  const [triggerFetchTurn, setTriggerFetchTurn] = useState(0);
  const [historyReward, setHistoryReward] = useState({
    isFetching: false,
    isHasMore: true,
    isLoading: false,
  });

  const [pageScroll, setPageScroll] = useState(PAGE_DEFAULT);
  const [spinnerMission, setSpinnerMission] = useState([]);
  const [isNextFreeSpin, setNextFreeSpin] = useState(false);
  const [shouldScroll, setShouldScroll] = useState(false);
  const [gamificationDetailID, setGamificationDetailID] = useState();
  const [isEffectOOT, setEffectOOT] = useState(false);
  const [isShowSharing, setShowSharing] = useState(false);
  const [redirectLink, setRedirectLink] = useState(WEB_HOST);
  const [isLoadingWheel, setLoadingWheel] = useState(true);
  const [mapRewardPosition, setMapRewardPosition] = useState([]);
  const [spinQuestion, setSpinQuestion] = useState({});
  const btnSpinRef = useRef(null);
  const btnSpinHeadRef = useRef(null);
  const didMountRef = useRef(false);
  const spinnerInfoRef = useRef(spinnerInfo);

  const topPosition = (num, angle) => {
    // find position of arrow selector
    // set starting index and angle offset based on list length
    // works upto 12 options
    let topSpot = null;
    let degreesOff = null;

    if (num <= 3) {
      if (num === 2) {
        // with set 90deg wheel
        topSpot = num;
        degreesOff = Math.PI / 2;
      }
      if (num === 3) {
        // with set 90deg wheel
        topSpot = num;
        degreesOff = (2 * Math.PI) / 3;
      }
    } else {
      topSpot = num;
      degreesOff = 0;
    }

    setSpinnerInfo((prev) => ({ ...prev, top: topSpot, offset: degreesOff }));
  };

  const loadImage = (image) =>
    new Promise((resolve, reject) => {
      // eslint-disable-next-line no-param-reassign
      image.onload = () => resolve(image);
      // eslint-disable-next-line no-param-reassign
      image.onerror = (err) => reject(err);
    });

  const initDrawReward = (ctx, PI, sweep, cx, cy, radius) => {
    const rewardCount = spinnerInfo.rewards.length;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < rewardCount; i++) {
      const { itemName: name, itemUrl: imageUrl, backgroundColor: bgc = '#fff' } = spinnerInfo.rewards[i];
      const image = new Image();
      image.onload = () => {
        let imageItemW = 0;
        let imageItemH = 0;
        let imageItemX = 0;
        let imageItemY = 0;

        // item is square
        if (image.naturalWidth === image.naturalHeight) {
          if (rewardCount > 9) {
            imageItemW = isMobile ? 116 : 116;
            imageItemH = isMobile ? 116 : 116;
            imageItemX = isMobile ? -radius + 280 : -radius + 280;
            imageItemY = -radius + 10; // space from angle
          } else {
            imageItemW = isMobile ? 128 : 128;
            imageItemH = isMobile ? 128 : 128;
            imageItemX = isMobile ? -radius + 280 : -radius + 280; // 31
            imageItemY = -radius + 15; // space from angle
          }
        }

        // item !== square
        if (image.naturalWidth !== image.naturalHeight) {
          if (rewardCount >= 10) {
            imageItemW = isMobile ? 124 : 128;
            imageItemH = isMobile ? 62 : 64;
            imageItemX = isMobile ? -radius + 275 : -radius + 275;
            imageItemY = -radius + 20; // space from angle
          } else if (rewardCount > 6 && rewardCount < 10) {
            imageItemW = isMobile ? 148 : 148;
            imageItemH = isMobile ? 84 : 84;
            imageItemX = isMobile ? -radius + 265 : -radius + 265;
            imageItemY = -radius + 20; // space from angle
          } else {
            imageItemW = isMobile ? 204 : 204;
            imageItemH = isMobile ? 102 : 102;
            imageItemX = isMobile ? -radius + 240 : -radius + 240;
            imageItemY = -radius + 20; // space from angle
          }
        }

        drawReward(ctx, PI, sweep, image, imageItemW, imageItemH, imageItemX, imageItemY, name, cx, cy, radius, sweep * i, sweep, bgc);
      };
      image.src = getLinkProxyCDN(imageUrl);
    }
  };
  const renderSector = () => {
    const canvas = document.getElementById('wheel');
    if (canvas) {
      const ctx = canvas.getContext('2d');
      const cw = canvas.width * 2;
      const ch = canvas.height * 2;

      const { PI } = Math;
      const PI2 = PI * 2;
      const rewardCount = spinnerInfo.rewards.length;
      const sweep = PI2 / rewardCount;

      const cx = cw / 8;
      const cy = ch / 8;
      const { radius } = spinnerInfo;
      if (ctx) {
        initDrawReward(ctx, PI, sweep, cx, cy, radius);
      }
    }
  };
  const renderWheel = () => {
    // determine number/size of sectors that need to created
    const numOptions = spinnerInfo.rewards.length || 0;
    const centralAngle = 360 * (1 / numOptions) * (Math.PI / 180);

    setSpinnerInfo((prev) => ({ ...prev, angle: centralAngle }));
    // get index of starting position of selector
    topPosition(numOptions, centralAngle);
    // dynamically generate sectors from state list
    for (let i = 0; i < numOptions; i += 1) {
      renderSector();
    }
  };

  const renderBorder = () => {
    const canvas = document.getElementById('border-wheel');
    if (canvas) {
      const ctx = canvas.getContext('2d');
      const cw = canvas.width;
      const ch = canvas.height;
      const backgroundWheel = new Image();
      backgroundWheel.src = LUCKYWHEEL_BORDER_WITH_CENTER;

      if (ctx) {
        backgroundWheel.onload = () => {
          ctx.clearRect(0, 0, cw, ch);
          if (!isMobile) {
            ctx.drawImage(backgroundWheel, cw - 397.5, ch - 395, cw - 5, ch - 10);
          } else {
            ctx.drawImage(backgroundWheel, cw - 290, ch - 290, cw - 20, ch - 20);
          }
        };
      }
    }
  };

  const renderSelector = () => {
    const canvas = document.getElementById('selector-wheel');
    if (canvas) {
      const ctx = canvas.getContext('2d');
      const cw = canvas.width;
      const ch = canvas.height;
      const selectorWheel = new Image();
      selectorWheel.src = LUCKYWHEEL_ARROW_SELECTOR;
      if (ctx) {
        selectorWheel.onload = () => {
          ctx.clearRect(0, 0, cw, ch);
          if (!isMobile) {
            ctx.drawImage(selectorWheel, cw / 2 - 14, 0, 30, 87);
          } else {
            ctx.drawImage(selectorWheel, cw / 2 - 10, 0, 22, 65);
          }
        };
      }
    }
  };

  const renderCenterWheel = () => {
    const canvas = document.getElementById('center-wheel');
    if (canvas) {
      const ctx = canvas.getContext('2d');
      const cw = canvas.width;
      const ch = canvas.height;
      const backgroundWheel = new Image();
      backgroundWheel.src = LUCKYWHEEL_CENTER;

      if (ctx) {
        backgroundWheel.onload = () => {
          ctx.clearRect(0, 0, cw, ch);
          ctx.shadowColor = 'black';
          ctx.shadowBlur = 22;
          if (!isMobile) {
            ctx.drawImage(backgroundWheel, cw / 2 - 25, ch / 2 - 25, cw / 8, ch / 8); // 400
          } else {
            ctx.drawImage(backgroundWheel, cw / 2 - 17.5, ch / 2 - 17.5, cw / 8, ch / 8);
          }
        };
      }
    }
  };

  const inActiveBtnSpin = () => {
    if (btnSpinHeadRef && btnSpinHeadRef.current) {
      btnSpinHeadRef?.current?.classList.remove(styles.btnSpin_active);
    }
  };

  const activeButtonSpin = () => {
    if (btnSpinHeadRef && btnSpinHeadRef.current) {
      btnSpinHeadRef?.current?.classList.add(styles.btnSpin_active);
    }
  };

  const getResult = (reward) => {
    // set state variable to display result
    setSpinnerInfo((prev) => ({
      ...prev,
      result: reward,
      isStop: true,
    }));

    setTriggerFetchTurn((prev) => prev + 1);

    setOpenPopup((prev) => ({
      ...prev,
      gotPrize: true,
    }));
  };

  const spin = async ({ questionCode = '', answerId = null }) => {
    // TODO: Get detail reward
    if (!spinnerInfo.luckyWheelCode) {
      NotifyUtils.error('notFound');
      return;
    }

    let resSpin;
    const currentDate = new Date();
    let nextTimeDate;

    if (spinnerInfo.nextFreeTime) {
      nextTimeDate = new Date(spinnerInfo?.nextFreeTime);
    }

    const isFreeSpin = currentDate.getTime() > nextTimeDate?.getTime();
    if (spinnerInfo.isAcceptFreeTurn && isFreeSpin) {
      // free spin
      resSpin = await postSpinLuckyWheel({ code: spinnerInfo.luckyWheelCode, type: 'FREE', questionCode, answerId });
    } else {
      resSpin = await postSpinLuckyWheel({ code: spinnerInfo.luckyWheelCode, questionCode, answerId });
    }

    if (isValid(resSpin)) {
      setSpinQuestion(null)
      activeButtonSpin();
      const { index, itemCode } = getFirst(resSpin);
      let idx;
      if (rewardIdxMap.get(itemCode)) {
        idx = spinnerInfo.top - (rewardIdxMap.get(itemCode) ?? index); // idx will start at 0
      } else {
        // keep allow rotate, accept wrong place spin on, but make sure display correct reward
        idx = null;
      }

      // TODO: specify will base on value from API by id
      let specifySpin = 0;
      const fullRots = Math.floor(Math.random() * 5) + 5; // Full spins the wheel should turn (min = 5, max = 10)
      if (idx === null) {
        specifySpin = fullRots * 360;
      } else {
        const arcLength = (2 * Math.PI) / spinnerInfo.rewards.length;
        const rotMax = arcLength * idx + arcLength - arcLength / 5; // reduce 20% width replace for wrong rate
        const rotMin = arcLength * (idx + 1) - arcLength + arcLength / 5; // add 20% width replace for wrong rate
        specifySpin = fullRots * 360 + getRandomInt(rotMin, rotMax) * RAD_TO_DEG;
      }

      setSpinnerInfo((prev) => ({
        ...prev,
        rotate: specifySpin,
        easeOut: 4,
        spinning: true,
        isStop: false,
        // turns: isFreeSpin ? prev.turns : prev.turns - 1,
      }));

      // calcalute result after wheel stops spinning
      setTimeout(() => {
        const formatData = {
          ...getFirst(resSpin),
          message: resSpin.message,
        };
        getResult(formatData);
      }, 4000);
    } else {
      // empty turn wheel
      switch (resSpin.errorCode) {
        case 'WRONG_ANSWER': {
          const { answerId: wrongAnswerId = '', correctAnswerId = '' } = getFirst(resSpin) || {};
          const newQuestion = { ...spinQuestion, wrongAnswerId, correctAnswerId };
          setSpinQuestion(newQuestion);
          break;
        }
        case 'NOT_ENOUGH_ITEM': {
          setOpenPopup((prev) => ({ ...prev, outOfReward: true }));
          break;
        }
        case 'NOT_FOUND': {
          setOpenPopup((prev) => ({ ...prev, notFound: true }));
          break;
        }
        default: {
          // remove popup only show effect and change tab
          setHistoryBoardTab(HISTORY_BOARD_TAB.MISSION);
          setEffectOOT(true);
          break;
        }
      }
      inActiveBtnSpin();
    }
  };

  const reset = () => {
    // reset wheel and result
    setSpinnerInfo((prev) => ({
      ...prev,
      rotate: 0,
      easeOut: 0,
      result: null,
      spinning: false,
    }));
  };

  const handleResetPopupPrize = () => {
    // reset wheel and result
    setSpinnerInfo((prev) => ({
      ...prev,
      rotate: 0,
      easeOut: 0,
      result: null,
      spinning: false,
    }));

    inActiveBtnSpin();

    setOpenPopup((prev) => ({
      ...prev,
      gotPrize: false,
    }));
  };

  const handleClosePopup = () => {
    setOpenPopup({
      gotPrize: false,
      outOfReward: false,
      outOfTurn: false,
      notFound: false,
    });
  };

  const handleReloadPage = () => {
    router.reload();
  };

  const handleShowSharing = () => {
    setShowSharing(true);
  };

  const handleScrollToBottom = (e) => {
    const bottom = Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) <= 10;
    if (bottom) {
      if (spinnerLog && (!spinnerLog?.data || spinnerLog.total <= 20 || spinnerLog?.data.length >= spinnerLog?.total)) {
        setHistoryReward({
          ...historyReward,
          isHasMore: false,
        });
      }
      if (historyReward.isHasMore && !historyReward.isLoading) {
        setPageScroll((prev) => prev + 1);
      }
    }
  };

  const reRenderMission = async () => {
    const selfWheelMissionRes = await getMissionSelfLuckyWheel({ luckyWheelCode: spinnerInfo.luckyWheelCode });
    if (isValid(selfWheelMissionRes)) {
      const { data } = selfWheelMissionRes;

      if (data) {
        setSpinnerMission(data.filter((item) => item.isActive));
      }
    }
  };

  const handleFindMoreTurn = () => {
    setHistoryBoardTab(HISTORY_BOARD_TAB.MISSION);
    setEffectOOT(true);
    setOpenPopup({
      gotPrize: false,
      outOfReward: false,
      outOfTurn: false,
      notFound: false,
    });
  };

  const handleGetQuestion = async () => {
    if (spinnerInfo.turns <= 0) {
      setOpenPopup((prev) => ({ ...prev, outOfTurn: true }));
      return;
    }

    try {
      const spinQuestionRes = await PromoClient.getSpinQuestion({ luckyWheelCode: propWheelCode });
      if (!isValid(spinQuestionRes)) throw spinQuestionRes;
      setSpinQuestion(getFirst(spinQuestionRes));
    } catch (e) {
      spin({});
    }
  }

  const handleClosePopupQuestion = ({ isAnswerWrong = false }) => {
    if (isAnswerWrong) {
      setTriggerFetchTurn((prev) => prev + 1);
    }
    setSpinQuestion(null);
  }

  useEffect(() => {
    // get wheel and wheel's log
    (async () => {
      if (!propWheelCode) {
        setLoadingWheel(false);
        return false;
      }

      const selfWheelRes = await getSelfLuckyWheelRewards(propWheelCode);
      if (isValid(selfWheelRes)) {
        const { code, items, turns, description, nextFreeTime, isAcceptFreeTurn, isEnableShare, imageTitleWeb, backgroundWeb } =
          getFirst(selfWheelRes);

        const selfLogRes = await getLogSelfLuckyWheel({ luckyWheelCode: code, limit: PAGE_DEFAULT * 20 });
        const selfWheelMissionRes = await getMissionSelfLuckyWheel({ luckyWheelCode: code });

        if (!items || items.length === 0) {
          setLoadingWheel(false);
          return null;
        }

        const currentDate = new Date();
        const wheelNextFreeTime = new Date(nextFreeTime);
        const checkNextFreeTime = currentDate.getTime() >= wheelNextFreeTime.getTime();


        setSpinnerInfo((prev) => ({
          ...prev,
          luckyWheelCode: code,
          turns: checkNextFreeTime ? turns + 1 : turns,
          rewards: items?.filter((item) => item.isActive).sort((a, b) => a.index - b.index) || [],
          description,
          nextFreeTime,
          isAcceptFreeTurn,
          isEnableShare,
          imageTitleWeb: getLinkProxyCDN(imageTitleWeb) || LUCKYWHEEL_TITLE,
          backgroundWeb: getLinkProxyCDN(backgroundWeb) || (isMobile ? LUCKYWHEEL_MOBILE_BACKGROUND : LUCKYWHEEL_BACKGROUND),
        }));

        const newSpinnerIdxMap = new Map(
          items
            ?.filter((item) => item.isActive)
            .sort((a, b) => a.index - b.index)
            ?.map((child, idx) => [child.itemCode, idx + 1]),
        );
        setRewardIdxMap(newSpinnerIdxMap);

        if (isValid(selfLogRes)) {
          const { data, total } = selfLogRes;

          if (data && total && total > 0) {
            setSpinnerLog(selfLogRes);
          }
        }

        if (isValid(selfWheelMissionRes)) {
          const { data } = selfWheelMissionRes;

          if (data) {
            setSpinnerMission(data.filter((item) => item.isActive));
          }
        }
      }
      setLoadingWheel(false);
      return null;
    })();
  }, [triggerFetchTurn, isNextFreeSpin, propWheelCode]);

  useEffect(() => {
    // render wheel part
    if (spinnerInfo?.rewards?.length > 0) {
      renderWheel();
      renderBorder();
      renderSelector();
      renderCenterWheel();
    }
  }, [spinnerInfo.rewards]);

  useEffect(() => {
    // fetch more history log
    if (didMountRef.current) {
      (async () => {
        if (pageScroll !== 1) {
          setHistoryReward({ ...historyReward, isLoading: true });
          const selfLogRes = await getLogSelfLuckyWheel({ luckyWheelCode: spinnerInfo.luckyWheelCode, limit: pageScroll * 20 });
          if (selfLogRes.status === 'NOT_FOUND' || !historyReward.isHasMore) {
            setHistoryReward({ ...historyReward, isHasMore: false, isLoading: false });
          } else if (historyReward.isHasMore) {
            if (spinnerLog && spinnerLog.data) {
              setSpinnerLog((prev) => ({
                ...prev,
                data: [...getData(selfLogRes)],
                total: selfLogRes.total,
              }));
              setHistoryReward({ ...historyReward, isLoading: false });
            }
          }
        }
      })();
    } else {
      didMountRef.current = true;
    }
    return () => {
      setHistoryReward({ isFetching: false, isHasMore: true, isLoading: false });
    };
  }, [pageScroll]);

  useEffect(() => {
    const intervalFreeSpin = setInterval(() => {
      const currentDate = new Date();
      if (currentDate.getTime() >= new Date(spinnerInfoRef?.current?.nextFreeTime).getTime()) {
        setNextFreeSpin(true);
      } else {
        setNextFreeSpin(false);
      }
    }, 1000);

    return () => {
      clearInterval(intervalFreeSpin);
    };
  }, []);

  useEffect(() => {
    spinnerInfoRef.current = spinnerInfo;
  }, [spinnerInfo]);

  useEffect(() => {
    spinContainer.on('misson', () => {
      setHistoryBoardTab(HISTORY_BOARD_TAB.MISSION);
    });
    spinContainer.on('history', () => {
      setHistoryBoardTab(HISTORY_BOARD_TAB.HISTORY_REWARD);
    });
    spinContainer.on('guideline', () => {
      setHistoryBoardTab(HISTORY_BOARD_TAB.GUILDLINE);
    });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldScroll(true);
    }, 3000); // Adjust the delay time as needed

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const parentEle = document.getElementById('spinnerWrapper');
    if (parentEle) {
      parentEle.scrollLeft = 350;
    }
  }, [shouldScroll]);

  useEffect(() => {
    if (isEffectOOT) {
      setTimeout(() => {
        setEffectOOT(false);
      }, 5000);
    }
  }, [isEffectOOT]);

  const TitleTooltip = ({ name, url }) => (
    <Box className={styles.tooltipPrizeWrapper}>
      <Box className={styles.tooltipImageWrapper}>
        <NextImage src={getLinkProxyCDN(url)} alt={name} objectFit="contain" layout="fill" />
      </Box>
      <Typography variant="body2" className={styles.tooltipPrizeText}>
        {name}
      </Typography>
    </Box>
  );

  const TempPrizeElement = (props) => {
    const { listItem } = props;

    const containerDegree = () => {
      switch (listItem.length) {
        case 12:
          return -90 + 15;
        case 11:
          return -90 + 16;
        case 10:
          return -90 + 17;
        case 9:
          return -90 + 20;
        case 8:
          return -90 + 23;
        case 7:
          return -90 + 26;
        case 6:
          return -90 + 31;
        case 5:
          return -90 + 37;
        case 4:
          return -90 + 46;
        case 3:
          return -90 + 61;
        case 1:
          return -90 + 181;
        default:
          return 0;
      }
    };

    return (
      <Box
        style={{
          position: 'absolute',
          height: spinnerInfo?.radius,
          width: spinnerInfo?.radius,
          translate: '-50% -50%',
          rotate: `${containerDegree()}deg`,
          top: '50%',
          left: '50%',
        }}
      >
        {listItem
          ?.sort((a, b) => a.i - b.i)
          ?.map((item, idx) => (
            <LuckyWheelPrizeTooltip
              title={<TitleTooltip name={item.itemName} url={item.itemUrl} key={item.i} />}
              style={{ backgroundColor: '#fff' }}
              arrow
            >
              <div
                id={`tempPrizePostion_${item.i}`}
                style={{
                  width: item.itemW,
                  height: item.itemH,
                  top: item.itemY,
                  left: item.itemX,
                  rotate: `${-90 + item.i * (360 / spinnerInfo.rewards?.length)}deg`,
                  zIndex: 1,
                  // border: '1px solid red',
                }}
                className={styles.tempPrizePosition}
                data-name={item.itemName}
                data-url={item.itemUrl}
              />
            </LuckyWheelPrizeTooltip>
          ))}
      </Box>
    );
  };

  if (!user || !propWheelCode) {
    return <></>;
  }

  if (spinnerInfo && spinnerInfo.rewards?.length === 0) {
    if (!isLoadingWheel) {
      return (
        <Box width="100%" height={200} display="flex" alignItems="center" justifyContent="center">
          <Typography variant="body1" style={{ color: '#000', fontFamily: 'ggsr', fontSize: 18, textAlign: 'center' }}>
            {t('notTakePlace')}
          </Typography>
        </Box>
      );
    }
    return <></>;
  }

  return (
    <div id="spinnerWrapper" className={styles.spinnerReward_wrapper}>
      <Grid
        container
        className={isMobile ? styles.mobileSpinner_container : styles.luckyWheel_container}
        style={{ backgroundImage: `url(${spinnerInfo.backgroundWeb})` }}
      >
        {/* title */}
        <Grid item sm={3} md={3} className={isMobile ? styles.mobileSectionWheelTitle_container : styles.sectionWheelTitle_container}>
          <div
            className={isMobile ? styles.mobileWheelTitle_imageWrapper : styles.wheelTitle_imageWrapper}
            style={{ backgroundImage: `url(${spinnerInfo.imageTitleWeb})` }}
          />
          <CountDownLuckyUser code={spinnerInfo.luckyWheelCode} isMobile={isMobile} t={t} />
        </Grid>
        {/* wheel */}
        <Grid item sm={5} md={5}>
          <Box
            style={{
              position: 'relative',
              width: isMobile ? '100%' : '400px',
              borderRadius: '12px',
              margin: '0 auto',
            }}
          >
            <Box className={styles.drawCanvas_container}>
              <div id="spin_1" className={isMobile ? styles.mobileLuckyWheel_central : styles.luckyWheel_central} />
              <div className={styles.luckyWheel_borderWheel} />
              <div className={styles.luckyWheel_selector} />
              <canvas
                className={styles.canvasContainer}
                id="wheel"
                width="800"
                height="800"
                style={{
                  WebkitTransform: `rotate(${spinnerInfo.rotate - 90}deg)`,
                  WebkitTransition: `-webkit-transform ${spinnerInfo.easeOut}s ease-out`,
                  zIndex: 0,
                  width: '100%',
                }}
              />
              {isEffectOOT && <ArrowAnimation isWhite classes={styles.positionArrowEffect} />}
              {!spinnerInfo.spinning ? <TempPrizeElement listItem={mapRewardPosition} /> : null}
            </Box>
            <ButtonSpin
              // spin={spin}
              reset={reset}
              btnSpinHeadRef={btnSpinHeadRef}
              btnSpinRef={btnSpinRef}
              isSpinning={spinnerInfo.spinning}
              isStop={spinnerInfo.isStop}
              turns={spinnerInfo.turns}
              handleGetQuestion={handleGetQuestion}
            />
          </Box>
        </Grid>
        {/* historyReward */}
        <Box className={styles.boxHistoryReward}>
          <Box style={{ position: 'relative', height: '100%', width: '100%' }}>
            <Grid item sm={4} md={4} className={styles.historyBoard} id="spin_4">
              <Grid container className={styles.boardTab}>
                <Grid
                  item
                  sm={4}
                  md={4}
                  className={clsx(styles.missionTab, historyBoardTab === HISTORY_BOARD_TAB.MISSION && styles.active)}
                  onClick={() => setHistoryBoardTab(HISTORY_BOARD_TAB.MISSION)}
                >
                  <ImageFallbackStatic
                    src={LUCKYWHEEL_MISSION_ICON}
                    alt="mission icon"
                    width={29}
                    height={40}
                    style={{ userSelect: 'none', pointerEvents: 'none' }}
                  />
                  <Typography style={{ textAlign: 'center', fontFamily: 'ggsm' }}>{t('missions')}</Typography>
                </Grid>
                <Grid
                  item
                  sm={4}
                  md={4}
                  className={clsx(styles.historyReward, historyBoardTab === HISTORY_BOARD_TAB.HISTORY_REWARD && styles.active)}
                  onClick={() => setHistoryBoardTab(HISTORY_BOARD_TAB.HISTORY_REWARD)}
                >
                  <ImageFallbackStatic
                    src={LUCKYWHEEL_TIME_ICON}
                    alt="mission icon"
                    width={32}
                    height={40}
                    style={{ userSelect: 'none', pointerEvents: 'none' }}
                  />
                  <Typography style={{ textAlign: 'center', fontFamily: 'ggsm' }}>{t('history')}</Typography>
                </Grid>
                <Grid
                  item
                  sm={4}
                  md={4}
                  className={clsx(styles.guildLine, historyBoardTab === HISTORY_BOARD_TAB.GUILDLINE && styles.active)}
                  onClick={() => setHistoryBoardTab(HISTORY_BOARD_TAB.GUILDLINE)}
                >
                  <ImageFallbackStatic
                    src={LUCKYWHEEL_GUILDLINE_ICON}
                    alt="guidline icon"
                    width={60}
                    height={40}
                    style={{ userSelect: 'none', pointerEvents: 'none' }}
                  />
                  <Typography style={{ textAlign: 'center', fontFamily: 'ggsm' }}>{t('guide')}</Typography>
                </Grid>
              </Grid>
              {historyBoardTab === HISTORY_BOARD_TAB.HISTORY_REWARD && spinnerLog && spinnerLog.total > 0 && (
                <Box className={clsx(styles.boardContainer)}>
                  {historyReward.isLoading && LoaderContainer}
                  <Box className={styles.boardContainer_childList} onScroll={handleScrollToBottom}>
                    {spinnerLog?.data &&
                      spinnerLog?.data.map((item) => (
                        <Grid container className={styles.boardItem_container}>
                          <Grid item md={3} className={styles.boardItem_imageWrapper}>
                            <NextImage src={getLinkProxyCDN(item.itemImage ?? '')} width={80} height={40} />
                          </Grid>
                          <Grid item md={9}>
                            <Typography variant="body2" className={styles.boardItem_itemName}>
                              {convertLuckyMessage(item?.message, t)}
                            </Typography>
                            <span className={styles.boardItem_created}>{format(new Date(item.createdTime), 'HH:mm dd/MM/yyyy')}</span>
                          </Grid>
                        </Grid>
                      ))}
                  </Box>
                </Box>
              )}
              {historyBoardTab === HISTORY_BOARD_TAB.GUILDLINE && (
                <Box className={clsx(styles.boardGuildLine)}>
                  <Box className={styles.historyBoard_guildContainer}>
                    <div className={styles.historyBoard_guildLineText} dangerouslySetInnerHTML={{ __html: spinnerInfo.description }} />
                  </Box>
                </Box>
              )}
              {historyBoardTab === HISTORY_BOARD_TAB.MISSION && (
                <Box className={clsx(styles.boardContainer, isEffectOOT && styles.glowOnEffect)}>
                  <Box className={styles.boardContainer_childList}>
                    {spinnerMission &&
                      spinnerMission
                        ?.filter((item) => item.isActive)
                        ?.map((item) => (
                          <Box className={styles.boardMissionItem_container}>
                            <Box>
                              <Typography variant="body2" className={styles.boardMissionItem_itemName}>
                                {item.missionName}
                              </Typography>
                              <div
                                className={styles.boardItemMission_content}
                                dangerouslySetInnerHTML={{ __html: item?.condition?.description || '' }}
                              />
                              <Box className={clsx(item?.processInfos?.length > 0 && styles.processBar_container)}>
                                {item.processInfos &&
                                  item.processInfos?.map(
                                    (process) =>
                                      process.target !== 1 && (
                                        <Box style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                                          <Typography style={{ color: '#000' }} variant="body2">
                                            {convertMissionType(process.unitName, t)}
                                          </Typography>
                                          <Box sx={{ width: '100%', mr: 1, position: 'relative' }}>
                                            <BorderLinearProgress
                                              variant="determinate"
                                              value={process.value / process.target > 1 ? 100 : (process.value / process.target) * 100}
                                            />
                                            <span
                                              style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: '50%',
                                                transform: 'translate(-50%, -5%)',
                                                fontSize: '14px',
                                                fontFamily: 'ggsr',
                                                lineHeight: '22px',
                                              }}
                                            >
                                              {`${formatNumber(process.value, CURRENCY_SEPARATE, 2)} / ${formatNumber(process.target, CURRENCY_SEPARATE, 2)}`}
                                            </span>
                                          </Box>
                                        </Box>
                                      ),
                                  )}
                              </Box>
                            </Box>
                            {/* chu y cho nay */}
                            {item?.processInfos && item?.processInfos.filter((info) => info.isCompleted)?.length !== item?.processInfos?.length ? (
                              <Box style={{ marginLeft: 'auto' }}>
                                {item?.condition.actionName && (
                                  <Box style={{ marginLeft: 'auto' }}>
                                    <Button
                                      onClick={async () => {
                                        setGamificationDetailID(item.gamificationDetailID);
                                        if (item.condition.type === CONDITION_TYPE.SHARE) {
                                          // case sharing post
                                          setRedirectLink(item?.condition?.actionLink);
                                          setShowSharing(true);
                                        } else if (item.condition.type === CONDITION_TYPE.DISCOVER) {
                                          // case discover link on mission list
                                          const respScoreDiscover = await postGamificationScore({
                                            type: CONDITION_TYPE.DISCOVER,
                                            customerID,
                                            accountID,
                                            action: 'discovery mission',
                                            luckyWheelCode: spinnerInfo.luckyWheelCode,
                                            gamificationDetailID: item.gamificationDetailID,
                                          });
                                          if (respScoreDiscover.status === 'OK') {
                                            if (source === 'mobile_app') {
                                              window?.ReactNativeWebView?.postMessage(
                                                JSON.stringify({ type: 'deep_link', value: `${item?.condition?.actionLink}` }),
                                              );
                                            } else {
                                              router.push(item.condition.actionLink || '');
                                            }
                                          }
                                        } else if (source === 'mobile_app') {
                                          window?.ReactNativeWebView?.postMessage(
                                            JSON.stringify({ type: 'deep_link', value: `${item?.condition?.actionLink}` }),
                                          );
                                        } else {
                                          router.push(item.condition.actionLink || '');
                                        }
                                      }}
                                      className={clsx(item.isBlocked ? styles.boardMission_disablesBtn : styles.boardMissinItem_btn)}
                                    >
                                      {item.isBlocked ? (
                                        <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                          {t('notUnlock')}
                                          <NextImage src={LUCKYWHEEL_BLOCKED_ICON} width={15} height={20} />{' '}
                                        </span>
                                      ) : (
                                        item.condition.actionName
                                      )}
                                    </Button>
                                  </Box>
                                )}
                              </Box>
                            ) : (
                              <Box style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', color: '#797979', gap: 4 }}>
                                <CheckCircleOutlineIcon /> {t('completed')}
                              </Box>
                            )}
                            {item?.isBlocked && item?.parentMissionName !== '' && (
                              <Typography style={{ color: '#D55D2A', fontSize: 12 }}>
                                {t('completeMission')}{" "}
                                <span style={{ color: '#000' }}>{item?.parentMissionName}</span> {t('toUnlock')}
                              </Typography>
                            )}
                          </Box>
                        ))}
                  </Box>
                </Box>
              )}
            </Grid>
          </Box>
        </Box>

        {spinnerInfo.isEnableShare && (
          <Box className={isMobile ? styles.mobileSharingContainer : styles.sharingContainer} onClick={handleShowSharing}>
            <Box style={{ padding: '6px 0 0', height: 40 }}>
              <ImageFallbackStatic src={LUCKYWHEEL_SHARE_ICON} alt="share icon" width={32} height={32} />
            </Box>
            {t('share')}
          </Box>
        )}

        <PopupSharing
          isOpen={isShowSharing}
          handleClose={() => setShowSharing(false)}
          isMobile={isMobile}
          t={t}
          luckyWheelCode={spinnerInfo.luckyWheelCode}
          gamificationDetailID={gamificationDetailID}
          reRenderMission={reRenderMission}
          redirectLink={redirectLink}
        />

        <PopupWarning
          open={isOpenPopup.outOfTurn}
          handleClose={handleClosePopup}
          title={t('outOfTurn')}
          subTitle={t('outOfTurnWarning')}
          buttonText={t('findMoreTurn')}
          type="turn"
          handleFindMoreTurn={handleFindMoreTurn}
        />

        <PopupWarning
          open={isOpenPopup.outOfReward}
          handleClose={handleClosePopup}
          title={t('unbelievable')}
          buttonText={t('iUnderstand')}
          type="reward"
          handleFindMoreTurn={handleFindMoreTurn}
        />

        <PopupWarning
          open={isOpenPopup.notFound}
          handleClose={handleReloadPage}
          title={t('luckyWheelIsNotAvailable')}
          buttonText={t('reloadPage')}
          subTitle={t('pleaseReloadPage')}
          type="not_found"
          handleFindMoreTurn={handleFindMoreTurn}
        />

        <PopupGotPrize open={isOpenPopup.gotPrize} handleClose={handleResetPopupPrize} t={t} reward={spinnerInfo?.result} isMobile={isMobile} />

        <PopupQuestion
          open={!isEmpty(spinQuestion)}
          handleClose={handleClosePopupQuestion}
          question={spinQuestion}
          spin={spin}
          router={router}
          t={t}
        />
      </Grid>
    </div>
  );
};

const ButtonSpin = ({ spin, reset, btnSpinRef, btnSpinHeadRef, isSpinning, isStop, turns, handleGetQuestion }) => {
  const { t } = useTranslation('lucky-wheel');

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      ref={btnSpinRef}
      className={clsx(styles.btnSpin_container, !isStop && styles.btnSpin_containerDisabled)}
      onClick={isSpinning ? reset : handleGetQuestion}
    >
      <div id="spin_2" style={{ position: 'relative' }}>
        <div ref={btnSpinHeadRef} className={styles.btnSpin_head}>
          <span
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              width: '100%',
              color: '#fff',
              fontFamily: 'ggsm',
              fontSize: turns === 0 ? '26px' : '32px',
              textTransform: 'uppercase',
              userSelect: 'none',
              textShadow: '1px 3px #00000066',
            }}
          >
            {turns === 0 ? t('moreTurn') : t('spin')}
          </span>
        </div>
        <div className={styles.btnSpin_bottom}>
          <span
            style={{
              justifyContent: 'center',
              alignItems: 'flex-end',
              display: 'flex',
              width: '100%',
              color: '#fff',
              fontFamily: 'ggsm',
              position: 'absolute',
              bottom: '5px',
              userSelect: 'none',
            }}
          >
            {!turns ? t('remainZeroTurn') : t('remainTurns').replace('{turns}', turns)}
          </span>
        </div>
      </div>
    </div>
  );
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  p: '20px',
};

const PopupWarning = ({ open, handleClose, title, subTitle, buttonText, type, isMobile, handleFindMoreTurn }) => {
  const { t } = useTranslation('lucky-wheel');

  return (
    <Modal open={open}>
      <Box sx={style} style={{ outline: 0 }}>
        <Box
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            color: '#c0c0c0',
            cursor: 'pointer',
          }}
          onClick={handleClose}
        >
          <Close
            style={{
              stroke: '#c0c0c0',
              strokeWidth: 1,
            }}
          />
        </Box>
        <Box>
          <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', gap: 4 }}>
            <ImageFallbackStatic src={LUCKWHEEL_ICON_WARNING} alt="warning icon" width={40} height={40} />
            <Typography variant="h6" style={{ fontFamily: 'ggsm', lineHeight: '26px' }}>
              {title}
            </Typography>
          </Box>
          {type === 'reward' ? (
            <Typography className={styles.popupWarning_subTitle} variant="body2">
              {t('youOwnAllReward')}
            </Typography>
          ) : (
            <Typography className={styles.popupWarning_subTitle} variant="body2">
              {subTitle}
            </Typography>
          )}
        </Box>
        <Box style={{ textAlign: 'center', marginTop: '12px' }}>
          <Button onClick={() => (type === 'turn' ? handleFindMoreTurn() : handleClose())} className={styles.btnClosePopup}>
            {buttonText ?? 'Close'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const PopupGotPrize = ({ open, handleClose, reward, isMobile, t }) => {
  const RenderFireWork = () => {
    const numberFireWord = new Set([1, 2, 3]);
    return (
      <Box>
        {Array.from(numberFireWord, (child) => (
          <div key={child} className={styles.fireWork_effect} />
        ))}
      </Box>
    );
  };

  const highLightSplitText = (text, start, end) => {
    if (!text) return text;
    let startIndex = text.indexOf(start);
    const endIndex = text.lastIndexOf(end);

    if (startIndex === -1 || endIndex === -1) {
      return text;
    }

    startIndex += start.length;

    if (startIndex > endIndex) {
      return text;
    }

    return text.substring(startIndex, endIndex);
  };

  const textHightLight = (highLightSplitText(reward?.message, '<span style="color: #09884D;font-family: "ggsm">', '</span>') ?? reward?.message ?? '')
    .replace(LUCKY_NEXT_TIME, t('LUCKY_NEXT_TIME'))
    .replace(YOU_SPUN_AND_HIT, t('YOU_SPUN_AND_HIT'))
    .replace(WRONG_ANSWER, t('WRONG_ANSWER'));
  const encodeMessage = convertLuckyMessage(textHightLight, t);
  return (
    <Modal open={open}>
      <Box sx={style} style={{ outline: 0 }}>
        {reward?.reward && reward.reward.length > 0 && RenderFireWork()}
        <Box>
          {reward?.itemUrl && (
            <Box style={{ position: 'relative', width: '100%', height: '100px' }}>
              <NextImage src={getLinkProxyCDN(reward.itemUrl)} layout="fill" objectFit="contain" />
            </Box>
          )}
          <Typography className={isMobile ? styles.mobilePopupGotPrize_subTitle : styles.popupGotPrize_subTitle} variant="body2">
            <div
              style={{ fontSize: '16px' }}
              dangerouslySetInnerHTML={{
                __html: encodeMessage,
              }}
            />
          </Typography>
        </Box>
        <Box style={{ textAlign: 'center', marginTop: '12px', display: 'flex', gap: 12, width: '100%', justifyContent: 'center' }}>
          {reward?.actionName && reward?.actionLink && (
            <a href={reward.actionLink || ''} target="_blank" rel="noreferrer">
              <Button className={styles.btnPopupUsing}>{reward.actionName || t('useNow')}</Button>
            </a>
          )}
          <Button onClick={handleClose} className={styles.btnPopupReset}>
            {t('continue')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const CountDownLuckyUser = ({ code, isMobile, t }) => {
  const [luckyUserList, setLuckyUserList] = useState([]);
  const [offset, setOffset] = useState(0);
  const [opacity, setOpacity] = useState(false);
  const [currentLuckyUser, setCurrentLuckyUser] = useState([]); // display 4 for web
  const [currentIdx, setCurrentIdx] = useState(0);
  const luckyUserListRef = useRef(luckyUserList);
  const currentIdxRef = useRef(currentIdx);
  const offsetRef = useRef(offset);

  const filterLuckyUsers = async () => {
    setOpacity(false);
    const luckyUserTarget = [];
    const listLength = luckyUserListRef.current.length;

    // Use modulo to wrap around when reaching the end of the list
    // eslint-disable-next-line no-plusplus
    for (let i = currentIdxRef.current; i < currentIdxRef.current + 4; i++) {
      const index = i % listLength;
      luckyUserTarget.push(luckyUserListRef.current[index]);
    }

    setTimeout(() => {
      if (listLength === 0) {
        setOpacity(false);
        setCurrentLuckyUser([]);
      } else {
        setOpacity(true);
        setCurrentLuckyUser(luckyUserTarget);
        // Use modulo to wrap around when reaching the end of the list
        setCurrentIdx((prev) => (prev + 4) % listLength); // 4 user per time
      }
    }, 500);
  };

  const fetchLuckyUser = async () => {
    const selfLogOtherRes = await getLogSelfLuckyWheel({ luckyWheelCode: code, limit: 20, offset: 0, type: 'OTHER' });
    if (isValid(selfLogOtherRes)) {
      const { data } = selfLogOtherRes;

      if (data) {
        setLuckyUserList(data.map((item) => item.messageHidden));
        setOffset((prev) => prev + 20);
        filterLuckyUsers();
      }
    }

    setOffset((prev) => prev + 20);
    filterLuckyUsers();
  };

  useEffect(() => {
    fetchLuckyUser();
    const intervalFetchList = setInterval(async () => {
      const luckyUserResp = await getLogSelfLuckyWheel({
        luckyWheelCode: code,
        limit: 20,
        offset: offsetRef.current,
        type: 'OTHER',
      });

      if (isValid(luckyUserResp)) {
        const listMessage = getData(luckyUserResp).map((item) => item.messageHidden);

        setLuckyUserList((prev) => [...prev, ...listMessage]);
        setOffset((prev) => prev + 20);
      }
    }, 100 * 1000);

    const intervalFadeEffect = setInterval(async () => {
      filterLuckyUsers();
    }, 25 * 1000);

    return () => {
      clearInterval(intervalFetchList);
      clearInterval(intervalFadeEffect);
    };
  }, []);

  useEffect(() => {
    // Using ref for updating value in interval
    luckyUserListRef.current = luckyUserList;
    currentIdxRef.current = currentIdx;
    offsetRef.current = offset;
  }, [luckyUserList, currentIdx, offset]);

  return (
    <NoSsr>
      <Box
        display="flex"
        justifyContent="center"
        style={{
          transition: '0.8s linear',
        }}
      >
        <Box
          className={clsx(
            isMobile ? styles.mobileLuckyUser_container : styles.luckyUser_container,
            currentLuckyUser.length === 0 && styles.luckyUser_containerHidden,
          )}
        >
          <Box
            className={clsx(opacity ? styles.show : styles.hide, isMobile ? styles.mobileLuckyUser_messageContent : styles.luckyUser_messageContent)}
          >
            {currentLuckyUser.map((item) => (
              <p className={isMobile ? styles.mobileLuckyUser_messageContent : styles.luckyUser_messageContent}>{convertLuckyMessage(item, t)}</p>
            ))}
          </Box>
        </Box>
      </Box>
    </NoSsr>
  );
};

const PopupQuestion = ({ open = true, handleClose, t, question = {}, spin }) => {
  const [answerSeleted, setAnswerSeleted] = useState(null);

  const { title = '', question: questionName = '', answers = [], code = '', correctAnswerId = '', wrongAnswerId = '' } = question || {};

  const handleSelectAnswer = ({ answer }) => {
    if (wrongAnswerId) {
      return;
    }
    setAnswerSeleted(answer);
  };

  const handleSubmitAnswer = async () => {
    if (!answerSeleted) {
      NotifyUtils.error(t('pleaseSelectAnswer'));
      return;
    }
    setAnswerSeleted(null);
    spin({ questionCode: code || '', answerId: answerSeleted?.answerId || '' });
  };

  return (
    <Modal open={open} disableBackdropClick>
      <Grid container flexDirection='column' className={styles.popupQuestionContainer}>
        {title && (
          <Grid item xs={12}>
            <p className={styles.popupQuestionTitle}>
              {title}
            </p>
          </Grid>
        )}
        <Grid item xs={12} className={styles.popupQuestionName}>
          <span> {t('question')}: </span> {questionName}
        </Grid>
        {answers.length > 0 && (
          <Grid
            item
            xs={12}
            container
            justifyContent='center'
            alignItems='center'
            className={styles.answersContainer}
          >
            {answers.map((answer, idx) => (
              <Grid
                key={answer.answerId}
                item
                xs={12}
                md={6}
                className={clsx(
                  styles.answer,
                  answerSeleted?.answerId === answer.answerId && styles.answerSelected,
                  correctAnswerId === answer.answerId && styles.answerSuccess,
                  wrongAnswerId === answer.answerId && styles.answerWrong
                )}
                onClick={() => handleSelectAnswer({ answer })}
              >
                <span className={styles.answerText}> {answer.content} </span>
              </Grid>
            ))}
          </Grid>
        )}
        {
          wrongAnswerId
            ? (
              <>
                <Grid item xs={12} className={styles.wrongAnswerMessage}>
                  <span>{t('wrongAnswerMessage')}</span>
                </Grid>
                <Grid item xs={12}>
                  <Button onClick={() => handleClose({ isAnswerWrong: true })} className={styles.btnSubmitAnswer}>
                    <span>{t('close_and_go_back')}</span>
                  </Button>
                </Grid>
              </>
            )
            : (
              <Grid item xs={12}>
                <Button onClick={handleSubmitAnswer} className={styles.btnSubmitAnswer}>
                  <span>{t('submit')}</span>
                </Button>
              </Grid>
            )
        }
      </Grid>
    </Modal>
  );
};

export default LuckyWheel;
