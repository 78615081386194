import { ValidateUtils } from 'utils';

const validateForm = ({ name, phone, email, address, provinceCode, districtCode, wardCode }) => {
  if (ValidateUtils.isEmpty(name)) throw Error('message_empty_name');
  if (ValidateUtils.isEmpty(phone)) throw Error('message_empty_phone');
  if (ValidateUtils.isEmpty(address)) throw Error('message_empty_address');
  if (provinceCode === '0') throw Error('message_empty_province_city');
  if (districtCode === '0') throw Error('message_empty_district');
  if (wardCode === '0') throw Error('message_empty_ward');

  if (!ValidateUtils.isEmpty(email) && !ValidateUtils.validateEmail(email)) throw Error('message_invalid_email');
  if (!ValidateUtils.validatePhone(phone)) throw Error('message_invalid_phone');
};

export default validateForm;
