import { ButtonBase, Typography, Chip } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import PopupMultilImage from 'components-v2/mocules/PopupMultilImage';
import { MISSING_IMAGE } from 'constants/Images';
import { settingsMultiImageBoxV2 } from 'constants/data';
import useMultiImageBox from 'hooks/useMultiImageBox';
import useToggle from 'hooks/useToggle';
import Image from 'next/image';
import { useState } from 'react';
import Slider from 'react-slick';
import { myLoader } from 'utils';
import { ImageFallbackProductImage } from 'utils/ImageFallback';
import { getLinkProxy, getLinkProxyCDNFallback } from 'utils/ImageUtils';
import { v4 as uuidv4 } from 'uuid';
import TagComponent from 'components-v2/organisms/pages/landingPageThai/mocules/TagComponent';
import styles from './styles.module.css';

const MultiImageBoxV2 = ({
  loading,
  images = null,
  title = '',
  isMobile = false,
  imageName = '',
  translateProductDetail,
  nameLabel,
  product,
  link,
  tags,
  statusData,
  expiredDate,
}) => {
  const { selectedImage, handleHoverImage, handleImageSelection, handlePrevious, handleNext } = useMultiImageBox({ images });
  const { open, handleOpen, handleClose } = useToggle();
  const [mainImg, setMainImg] = useState(null);

  const handleOpenImageModal = () => {
    setMainImg(images[selectedImage]);
    handleOpen();
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapperImg}>
          {loading ? (
            <Skeleton variant="rect" classes={{ root: styles.imageMain }} />
          ) : (
            <>
              {images && images.length > 0 ? (
                <ButtonBase onClick={handleOpenImageModal} className="openImg">
                  <ImageFallbackProductImage
                    width={295}
                    height={295}
                    alt={title}
                    // fallbackSrc={images[selectedImage]}
                    fallbackSrc={getLinkProxyCDNFallback(images[selectedImage])}
                    className={styles.imageMain}
                    src={`${open ? getLinkProxy(mainImg) : getLinkProxy(images[selectedImage])}`}
                    quality={100}
                    // placeholder="blur"
                    // blurDataURL={`${open ? mainImg : images[selectedImage]}`}
                  />
                </ButtonBase>
              ) : (
                <Image width={295} height={295} alt="Image Not Found" loader={myLoader} className={styles.imageNotFound} src={MISSING_IMAGE} />
              )}
            </>
          )}
          {nameLabel && <Chip label={nameLabel} className={styles.product_tag} />}
          <div style={{ display: 'flex', marginLeft: -1, marginRight: -1 }} className={styles.product_tags}>
            <TagComponent
              product={{ tags, statusData, expiredDate }}
              isMobile={isMobile}
              isSellerList={false}
              isGift={product?.isGift}
              link={link}
              isLinkTagDeal
            />
          </div>
        </div>

        <Slider className={styles.slider} {...settingsMultiImageBoxV2}>
          {images &&
            images.map((src, index) => (
              <div className={styles.thumbnail} key={uuidv4()}>
                {loading ? (
                  <Skeleton variant="rect" classes={{ root: styles.thumbnailImage }} />
                ) : (
                  <ButtonBase onClick={handleOpenImageModal} onMouseOver={() => handleHoverImage(index)}>
                    <ImageFallbackProductImage
                      width={60}
                      height={60}
                      alt={title}
                      className={clsx(styles.thumbnailImage, {
                        [styles.thumbnailSelected]: index === selectedImage,
                      })}
                      src={getLinkProxy(src)}
                      fallbackSrc={getLinkProxy(src)}
                      quality={100}
                      // placeholder="blur"
                      // blurDataURL={src}
                    />
                  </ButtonBase>
                )}
              </div>
            ))}
        </Slider>
        <Typography className={styles.descImg}>* {translateProductDetail('images_may_change_overtime')}</Typography>
      </div>
      {open && (
        <PopupMultilImage
          isMobile={isMobile}
          images={images}
          open={open}
          handleClose={handleClose}
          imageName={imageName}
          selectedImage={selectedImage}
          handleClickImage={handleImageSelection}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
        />
      )}
    </>
  );
};

export default MultiImageBoxV2;
