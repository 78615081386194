import { useTranslation } from 'next-i18next';
import { formatNumber } from 'utils/FormatNumber';
import styles from './style.module.css';

export default function SearchResultText({ total = 0 }) {
  const { t: translateCommon } = useTranslation('common');
  return (
    <div className={styles.search_result}>
      <div>
        {translateCommon('text_search_result_1')} <strong>{formatNumber(total, ',')}</strong> {translateCommon('text_search_result_2')}
      </div>
    </div>
  );
}
