import { Box, Grid, IconButton, Modal, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import clsx from 'clsx';
import { useState } from 'react';
import FacebookShareButton from 'utils/FacebookShare';
import { postGamificationScore } from 'clients/PromoClient';
import { ENUM_CONDITION_TYPE, POPUP_SHARING_ITEM } from 'constants/Enums';
import { useAuth } from 'context';
import SocialSharing from '../SocialSharing';
import styles from './styles.module.css';

const styleSharing = {
  position: 'absolute',
  top: '55%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#fff',
  borderRadius: '8px',
  boxShadow: 24,
  p: '12px 16px',
  color: '#000',
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
};

const styleMobileSharing = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: '#fff',
  borderRadius: '8px',
  boxShadow: 24,
  p: '12px 16px',
  color: '#000',
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
};

const PopupSharing = ({
  isOpen = false,
  handleClose = () => {},
  isMobile = false,
  t = () => {},
  redirectLink = '',
  luckyWheelCode = '',
  gamificationDetailID = '',
  setOpenConfirmSharing = () => {},
  reRenderMission = () => {},
}) => {
  const { user } = useAuth();
  const [isCopied, setIsCopied] = useState(false);

  const completedMission = async (socialNetwork) => {
    try {
      let action = '';
      if (socialNetwork === POPUP_SHARING_ITEM.FACEBOOK) {
        action = 'share facebook';
      } else if (socialNetwork === POPUP_SHARING_ITEM.LINE) {
        action = 'share line';
      }
      const { accountID, customerID } = user || {};

      await postGamificationScore({
        type: ENUM_CONDITION_TYPE.SHARE,
        customerID,
        accountID,
        action,
        luckyWheelCode,
        gamificationDetailID,
        socialNetwork,
      });
      // await postShareLog({
      //   customerID,
      //   accountID,
      //   source: isMobile ? 'thuocsi-mobile' : 'thuocsi-web',
      //   page: getEnvDomain() + pathname,
      //   screen: pathname.replace('/', ''),
      //   shareUrl: redirectLink || domainBuymed,
      //   platform: POPUP_SHARING_ITEM.FACEBOOK,
      // });
      if (reRenderMission) {
        // set timeout here because the API above need a little time to update the data
        setTimeout(() => {
          reRenderMission();
        }, 5000);
      }
    } catch (error) {
      // share error
    }
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box sx={isMobile ? styleMobileSharing : styleSharing}>
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6" component="h2" style={{ fontFamily: 'ggsm' }}>
            {t('share')}
          </Typography>
          <IconButton onClick={handleClose} style={{ padding: 0, width: '32px', height: '32px' }}>
            <Close
              style={{
                stroke: '#c0c0c0',
                strokeWidth: 1,
              }}
            />
          </IconButton>
        </Box>
        <Grid container className={clsx(styles.sharingContent_wrapper, isMobile && styles.mobileSharingContent_wrapper)} spacing={2}>
          <SocialSharing socialPlatform="CURRENT" redirectLink={redirectLink} isMobile={isMobile} />
          <SocialSharing
            title="Share on LINE"
            socialPlatform="LINE"
            redirectLink={redirectLink}
            isMobile={isMobile}
            completedMission={completedMission}
          />
          <FacebookShareButton
            luckyWheelCode={luckyWheelCode}
            isSharePopup
            gamificationDetailID={gamificationDetailID}
            redirectLink={redirectLink}
            setOpenConfirmSharing={setOpenConfirmSharing}
            isMobile={isMobile}
            reRenderMission={reRenderMission}
            completedMission={completedMission}
          />
        </Grid>
      </Box>
    </Modal>
  );
};

export default PopupSharing;
