import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { CartClientV2, isValid } from 'clients';
import { useCart } from 'context';
import { useTranslation } from 'next-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { NotifyUtils } from 'utils';
import { debounceFunc500 } from 'utils/debounce';
import styles from './styles.module.css';

const CartNote = () => {
  const { t: translateCommon } = useTranslation('common');
  const { note } = useCart();
  const [noteValue, setNoteValue] = useState(note);
  const handleUpdateNote = useCallback(async (val) => {
    const res = await CartClientV2.updateNote(val);
    if (isValid(res)) {
      NotifyUtils.success(translateCommon('update_note_success'));
    } else {
      NotifyUtils.error(res?.message || translateCommon('update_note_fail'));
    }
  });

  const handleSetNote = (e) => {
    const valNote = e.target.value;
    setNoteValue(valNote);
    debounceFunc500(() => handleUpdateNote(valNote));
  };

  useEffect(() => {
    setNoteValue(note);
  }, [note]);

  return (
    <TextareaAutosize
      name="note"
      value={noteValue}
      onChange={handleSetNote}
      className={styles.text_area}
      aria-label="Ghi chú của khách hàng"
      placeholder={translateCommon('note_of_customer')}
      maxRows={4}
      data-test="note-cart"
    />
  );
};

export default React.memo(CartNote);
