// export const MobilePopupTitle = {
//   [ENUM_MOBILE_POPUP_TYPE.MISSON]: 'Nhiệm vụ',
//   [ENUM_MOBILE_POPUP_TYPE.HISTORY]: 'Lịch sử trúng thưởng',
//   [ENUM_MOBILE_POPUP_TYPE.GUILDLINE]: 'Hướng dẫn',
// };

// export type PopupWarningType = 'reward' | 'turn' | 'not_found';

// export type ConditionSocialType = 'FACEBOOK' | 'ZALO';

// export type SpinnerPopupType = 'MISSON' | 'HISTORY' | 'GUIDLINE'

export const MOBILE_POPUP_TYPE = {
  MISSON: 'MISSION',
  HISTORY: 'HISTORY',
  GUILDLINE: 'GUILDLINE',
};

export const HISTORY_BOARD_TAB = {
  HISTORY_REWARD: 'REWARD',
  GUILDLINE: 'GUILDLINE',
  MISSION: 'MISSION',
};
export const POPUP_SHARING_ITEM = {
  FACEBOOK: 'FACEBOOK',
  ZALO: 'ZALO',
  TELEGRAM: 'TELEGRAM',
  LINKEDIN: 'LINKEDIN',
  TWITTER: 'TWITTER',
  VIBER: 'VIBER',
};

export const CONDITION_TYPE = {
  SHARE: 'SHARE',
  DISCOVER: 'DISCOVER',
};
