import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button as ButtonMUI, FormControl, IconButton, InputAdornment } from '@material-ui/core';
import { LockOutlined as LockOutlinedIcon } from '@material-ui/icons';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import ErrorIconOutlined from '@material-ui/icons/ErrorOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import clsx from 'clsx';
import MobileBadge from 'components-v2/atoms/MobileBadge';
import DeleteAccountMessage from 'components-v3/atoms/DeleteAccountMessage';
import { Button, CheckBox, Input, LinkComp } from 'components/atoms';
import { BRAND_NAME } from 'constants/Enums';
import { ICON_MOBILE_ICON_HOME, MOBILE_LOGO } from 'constants/Images/mobile/Icons';
import { LINK_DIRECT_TO_TELE } from 'constants/data/mobile';
import { useAuth } from 'context';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ENV_COUNTRY } from 'sysconfig';
import * as yup from 'yup';
import useMobileV2 from 'zustand-lib/storeMobile';
import styles from '../NewSignUpForm/styles.module.css';

const NewSignInForm = ({
  className,
  onClickForget,
  onClickLogin,
  onClickSignUp,
  isLoading = false,
  page = null,
  setPage = null,
  user,
  translatePopupAction,
  translateMyAccount,
}) => {
  const schema = yup
    .object({
      username: yup.string().required(translatePopupAction('popup_login.validate_phone_number_email_empty')),
      password: yup.string().required(translatePopupAction('popup_login.validate_password_empty')),
    })
    .required();
  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({ resolver: yupResolver(schema) });
  const {
    isShowLogin,
    isShowSignUp,
    isShowDeleteAccountMess,
    toggleShowDeleteAccountMess,
    toggleLogin,
    toggleSignUp,
    getValResponseDeleteAccountMess
  } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const router = useRouter();
  const beta = useMobileV2((state) => state.beta);
  const isMobileV2 = useMobileV2((state) => state.isMobileV2());
  // handle login with data
  const handleLogin = (data) => {
    onClickLogin(data);
  };

  // handle forget password
  const handleClickForget = useCallback(
    (e) => {
      if (isMobileV2 && beta) {
        e.preventDefault();
        router.push('/?forgetpass=true');
        setPage(page + 2);
      } else {
        e.preventDefault();
        onClickForget();
      }
    },
    [onClickForget, page, isMobileV2],
  );

  const handleClickSignUp = useCallback(
    (e) => {
      if (isMobileV2 && beta) {
        e.preventDefault();
        router.push('/?register=true');
        setPage(page + 1);
      } else {
        e.preventDefault();
        onClickSignUp();
      }
    },
    [onClickSignUp, page, isMobileV2],
  );

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleOpenChat = async () => {
    toggleLogin();
    const supportCenter = document?.getElementById('support-center');
    if (supportCenter) {
      const supportCenterIcon = supportCenter?.getElementsByTagName('img');
      supportCenterIcon[0]?.click();
      if (!isMobileV2) {
        setTimeout(() => {
          const chat = document?.getElementById('open-chat');
          if (chat) {
            const chatIcon = chat?.getElementsByTagName('img');
            chatIcon[0]?.click();
          }
        }, 250);
      }
    }
    toggleShowDeleteAccountMess();
  }

  const IconAccount = (
    <InputAdornment position="start" className={styles.input_icon}>
      <AccountCircleOutlinedIcon />
    </InputAdornment>
  );

  const IconPassword = (
    <InputAdornment position="start" className={styles.input_icon}>
      <LockOutlinedIcon />
    </InputAdornment>
  );

  const IconEndPassword = (
    <InputAdornment position="end" className={styles.input_icon}>
      <IconButton onClick={handleClickShowPassword}>{showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}</IconButton>
    </InputAdornment>
  );

  const mapButtonForgetPassword = {
    vn: (
      <a href="/" className={styles.link_login} onClick={handleClickForget}>
        {translatePopupAction('popup_login.forgot_your_password')}
      </a>
    ),
    cam: (
      <a href={LINK_DIRECT_TO_TELE} target="_blank" className={styles.link_login} rel="noreferrer">
        {translatePopupAction('popup_login.forgot_your_password')}
      </a>
    ),
    th: (
      <a href="/" onClick={handleClickForget} className={styles.link_login}>
        {translatePopupAction('popup_login.forgot_your_password')}
      </a>
    ),
  };

  return (
    <div className={className}>
      {beta && isMobileV2 && (
        <div className={styles.wrapperTitle}>
          <Box marginBottom="100px" marginTop="20px" display="flex" justifyContent="space-between">
            <LinkComp href="/">
              <MOBILE_LOGO className={styles.logo} />
            </LinkComp>
            {isShowLogin && (
              <MobileBadge className={clsx(styles.badge, styles.greenIcon)} onClick={toggleLogin}>
                <ICON_MOBILE_ICON_HOME />
              </MobileBadge>
            )}
            {isShowSignUp && (
              <MobileBadge className={clsx(styles.badge, styles.greenIcon)} onClick={toggleSignUp}>
                <ICON_MOBILE_ICON_HOME />
              </MobileBadge>
            )}
          </Box>
          <p className="title">
            {translatePopupAction('popup_login.greeting')} {BRAND_NAME}
          </p>
          <p className="desc">{translatePopupAction('popup_login.sub_title')}</p>
        </div>
      )}

      <form className={className} onSubmit={handleSubmit((data) => handleLogin(data))}>
        <Controller
          name="username"
          defaultValue=""
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <Input
                error={!!errors.username}
                startAdornment={IconAccount}
                placeholder={translatePopupAction('popup_login.placeholder_username')}
                variant="outlined"
                className={styles.input}
                {...field}
                data-test="signin-username"
              />
              {errors.username?.message && (
                <div className={styles.text_error} data-test="signin-err-username">
                  <ErrorIconOutlined /> {errors.username?.message}
                </div>
              )}
            </FormControl>
          )}
        />
        <Controller
          name="password"
          defaultValue=""
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <Input
                error={!!errors.password}
                type={showPassword ? 'text' : 'password'}
                startAdornment={IconPassword}
                endAdornment={IconEndPassword}
                placeholder={translatePopupAction('popup_login.password')}
                autoComplete="password"
                variant="outlined"
                className={styles.input}
                {...field}
                data-test="signin-password"
              />
              {errors.password?.message && (
                <div className={styles.text_error} data-test="signin-err-password">
                  <ErrorIconOutlined /> {errors.password?.message}
                </div>
              )}
            </FormControl>
          )}
        />
        <Controller
          name="rememberMe"
          defaultValue
          control={control}
          render={({ field }) => (
            <div className={styles.agree_term} style={{ padding: '0 0 1rem 0' }}>
              <CheckBox
                data-test="signin-rememberMe"
                label={translatePopupAction('popup_login.keep_me_signed_in')}
                onChange={(e) => field.onChange(e.target.checked)}
                isChecked={field.value}
                {...field}
              />
            </div>
          )}
        />
        {
          isShowDeleteAccountMess ? (
            <>
              <DeleteAccountMessage code={getValResponseDeleteAccountMess()?.errorCode || ''} translateMyAccount={translateMyAccount} />
              <Box className={styles.center}>
                <ButtonMUI color="warning" className={styles.btn_call_to_action} onClick={handleOpenChat}>
                  {translatePopupAction('contact_directly')}
                </ButtonMUI>
              </Box>
            </>
          ) : (
            <>
              <div className={`${styles.link_login_wrapper} ${styles.center}`}>{mapButtonForgetPassword[ENV_COUNTRY]}</div>
              <Box className={styles.center}>
                <Button className={styles.btn_register} type="submit" disabled={isLoading} data-test="signin-submit">
                  {translatePopupAction('popup_login.sign_in')}
                </Button>
              </Box>
              <div className={`${styles.link_login_wrapper} ${styles.center}`}>
                <p>
                  {translatePopupAction('popup_login.get_deal_member')},&nbsp;
                  <a href="#top" className={styles.link_login} onClick={handleClickSignUp}>
                    {translatePopupAction('popup_login.register_member')}
                  </a>
                </p>
              </div>
            </>
          )
        }


      </form>
      {/* {ENV_COUNTRY === 'vn' && beta && isMobileV2 && !checkNewUser(user?.account?.createdTime) && (
        <Box display="flex" justifyContent="center">
          <Grid component="label" container alignItems="center" justifyContent="center" spacing={1}>
            <Grid item style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Switch color="primary" checked={beta} onChange={handleChangeUI} name="checkedMobileNew" />
              <div style={{ fontFamily: 'googlesansregular', fontSize: '14px', color: '#000000DE' }}>
                {translatePopupAction('popup_login.trial_mv2_switch')}
              </div>
            </Grid>
          </Grid>
        </Box>
      )} */}
    </div>
  );
};

export default NewSignInForm;
