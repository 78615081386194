import { Grid } from '@material-ui/core';
import { LinkComp } from 'components/atoms';
import { useAuth } from 'context';
import { useTranslation } from 'next-i18next';

const AlertRequestLogin = ({ bgColor }) => {
  const { t: translateCommon } = useTranslation('common');
  const { toggleLogin, toggleSignUp } = useAuth();

  return (
    <Grid
      container
      style={{ background: bgColor || '#F7F8FB', maxWidth: '400', borderRadius: '5px', padding: '5px', fontSize: '14px' }}
      alignItems="center"
    >
      {translateCommon('message_please')}{' '}
      <LinkComp style={{ marginLeft: '2px', color: '#0E1983', cursor: 'pointer', fontSize: '14px' }} href="#" onClick={toggleLogin}>
        {translateCommon('login')}
      </LinkComp>
      /
      <LinkComp style={{ marginRight: '2px', color: '#0E1983', cursor: 'pointer', fontSize: '14px' }} href="#" onClick={toggleSignUp}>
        {translateCommon('signup')}
      </LinkComp>{' '}
      {translateCommon('message_view_detail')}
    </Grid>
  );
};

export default AlertRequestLogin;
