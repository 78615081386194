import { ValidateUtils } from 'utils';

const validateForm = ({ type, phone, orderId, orderDetail, sku, email }) => {
  // if (ValidateUtils.isEmpty(feedbackContent)) throw Error('message_feedback_empty');
  if (ValidateUtils.isEmpty(type)) throw Error('message_not_choose_feedback_type');
  if (email && !ValidateUtils.validateEmail(email)) throw Error('message_wrong_email');

  switch (type) {
    case 'PRODUCT': {
      if (!sku || ValidateUtils.isEmpty(sku)) throw Error('message_not_choose_product');
      if (orderId && !orderDetail) throw Error('message_wrong_order_code');
      if (phone && !ValidateUtils.validatePhone(phone)) throw Error('message_invalid_phone');
      break;
    }
    case 'ORDER': {
      if (!orderId || ValidateUtils.isEmpty(orderId)) throw Error('message_empty_order_info');
      if (!orderDetail) throw Error('message_wrong_order_code');
      break;
    }
    case 'ACCOUNT':
    case 'OTHER ': {
      if (ValidateUtils.isEmpty(phone)) throw Error('message_not_input_phone');
      if (!ValidateUtils.validatePhone(phone)) throw Error('message_invalid_phone');
      break;
    }
    default: {
      break;
    }
  }
};

export default validateForm;
