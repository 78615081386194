import { Grid, Typography } from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';
import { postGamificationScore } from 'clients/PromoClient';
import { ENUM_CONDITION_TYPE, POPUP_SHARING_ITEM } from 'constants/Enums';
import { useRouter } from 'next/router';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { NotifyUtils } from 'utils';

const SocialSharing = ({ title = '', socialPlatform = '', redirectLink = '', isMobile = false, completedMission = (socialNetwork) => { } }) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { pathname } = router;

  const handleCopyLinkSharing = () => {
    NotifyUtils.success(t('copiedToTheClipBoard'));
  };

  const handleShareLine = async () => {
    window.open(`https://line.me/R/msg/text/?${encodeURIComponent(redirectLink)}`, '_blank');
    completedMission(POPUP_SHARING_ITEM.LINE);
  };

  switch (socialPlatform) {
    case POPUP_SHARING_ITEM.LINE: {
      return (
        <Grid
          item
          xs={3}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div role='button' onClick={handleShareLine}>
            <img src="/static/images/footer/line-2.svg" alt="line-icon" style={{ width: 32, height: 32 }} />
          </div>
          <Typography variant="body2">Line</Typography>
        </Grid>
      );
    }
    case 'CURRENT': {
      return (
        <Grid
          item
          xs={3}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <CopyToClipboard text={redirectLink || window.location.href} onCopy={handleCopyLinkSharing}>
            <FileCopyOutlined style={{ height: 32, width: 32 }} />
          </CopyToClipboard>
          <Typography variant="body2">{t('common:copy_link')}</Typography>
        </Grid>
      );
    }
    default:
      break;
  }

  return <></>;
};

export default SocialSharing;
