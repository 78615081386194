/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-target-blank */

import { Grid, Typography } from '@material-ui/core';
import FacebookIcon from '@mui/icons-material/Facebook';
import { postGamificationScore, postShareLog } from 'clients/PromoClient';
import { ENUM_CONDITION_TYPE, POPUP_SHARING_ITEM } from 'constants/Enums';
import { useAuth } from 'context/Auth';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ENV, FACEBOOK_APP_ID } from 'sysconfig';
import { FBCheckLogin, cleanup, initFBSharing } from './fb';

const styleContainer = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px',
  backgroundColor: '#4b67b7',
  padding: '4px 6px',
  color: '#fff',
  fontFamily: 'ggsm',
  borderRadius: '4px',
  maxWidth: 'max-content',
};

const styleWrapper = {
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'space-between',
  position: 'relative',
};

const styleFacebookIframe = {
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  position: 'relative',
};

// const domainBuymed = 'https://thuocsi.vn';
const currentTime = new Date().getTime();

export const checkNextSymbol = (link) => {
  const regex = /[?]\w/g;
  if (regex.test(link)) {
    return '&';
  }
  return '?';
};

export default function FacebookShareButton({
  luckyWheelCode = '',
  isSharePopup = false,
  gamificationDetailID,
  redirectLink = '',
  setOpenConfirmSharing,
  isMobile = false,
  reRenderMission,
  completedMission = (socialNetwork) => {},
}) {
  const getEnvDomain = () => {
    if (ENV === 'prd' || ENV === 'uat') {
      return 'https://buymed.co.th';
    }
    return 'https://stg.th.buymed.tech';
  };

  const domainBuymed = `${getEnvDomain()}/lucky-wheel`;

  // const postInfo = {
  //   method: 'feed',
  //   name: 'Sharing post',
  //   link: redirectLink || domainBuymed,
  // };

  const router = useRouter();
  // const { pathname } = router;
  const { user } = useAuth();
  const { t } = useTranslation();
  // const { accountID, customerID } = user || {};

  useEffect(() => {
    initFBSharing();

    return () => {
      cleanup();
    };
  }, []);

  const handleOpenSharePopup = async () => {
    try {
      const shareLink = redirectLink || domainBuymed;
      const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareLink)}`;
      window.open(url, '_blank');
      // FBCheckLogin({ postInfo, user, luckyWheelCode, gamificationDetailID, setOpenConfirmSharing, reRenderMission });
      completedMission(POPUP_SHARING_ITEM.FACEBOOK);
    } catch (error) {
      console.log(error);
    }
  };

  if (!user) {
    return <></>;
  }

  if (isSharePopup) {
    return (
      <Grid item xs={3} md={3} style={styleWrapper}>
        <div
          // class="fb-share-button lucky-wheel-fb-share-btn"
          class="lucky-wheel-fb-share-btn"
          style={styleFacebookIframe}
          // data-href={redirectLink || domainBuymed}
          // data-layout="button"
          // data-size="large"
          onClick={() => {
            handleOpenSharePopup();
          }}
        >
          {/* <a
            target="_blank"
            href={`http://www.facebook.com/sharer.php?&u=${encodeURIComponent(redirectLink || domainBuymed)}`}
            onClick={completedMission}
            class="fb-xfbml-parse-ignore"
          >
            .
          </a> */}
        </div>
        {/* <div id="fb-root" /> */}

        <Typography variant="body2">Facebook</Typography>
        <Script src={`https://connect.facebook.net/en_GB/all.js#xfbml=1&appId=${FACEBOOK_APP_ID}`} strategy="lazyOnload" />
      </Grid>
    );
  }

  return (
    <div>
      <div
        id="fb-button-share"
        style={styleContainer}
        onClick={() => {
          handleOpenSharePopup();
        }}
      >
        <FacebookIcon /> {t('common:share')}
      </div>

      <Script src={`https://connect.facebook.net/en_GB/all.js#xfbml=1&appId=${FACEBOOK_APP_ID}`} strategy="lazyOnload" />
    </div>
  );
}
