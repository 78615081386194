const MAPPING_CODE_TO_TEXT = {
  LUCKY_NEXT_TIME: 'LUCKY_NEXT_TIME',
  YOU_SPUN_AND_HIT: 'YOU_SPUN_AND_HIT',
  CONGRATULATIONS_TO: 'CONGRATULATIONS_TO',
  FOR_SUCCESSFULLY_SPINNING_AND_WINNING: 'FOR_SUCCESSFULLY_SPINNING_AND_WINNING',
  LUCKY_WHEEL_NOT_FOUND: 'LUCKY_WHEEL_NOT_FOUND',
  CUSTOMER_NOT_FOUND: 'CUSTOMER_NOT_FOUND',
  REWARD_POINT: 'REWARD_POINT',
  TURN: 'TURN',
  TICKET_PATTERN: 'TICKET_PATTERN',
  WRONG_ANSWER: 'WRONG_ANSWER',
};

const MAPPING_MISSION_TYPE_TO_TEXT = {
  QUANTITY_OF_PRODUCTS: 'Quantity of products',
  NUMBER_OF_ORDERS: 'Number of orders',
  TOTAL_VALUE_ORDERED: 'Total value ordered',
  NUMBER_OF_SHARES: 'Number of shares',
  NUMBER_OF_EXPLORATIONS: 'Number of explorations',
};
export const convertLuckyMessage = (message = '', t) => {
  let encodeMessage = message;
  Object.keys(MAPPING_CODE_TO_TEXT).forEach((key) => {
    encodeMessage = encodeMessage.replace(key, t(key));
  });
  return encodeMessage;
};

export const convertMissionType = (mission = '', t) => {
  let encodeMessage = mission;
  Object.keys(MAPPING_MISSION_TYPE_TO_TEXT).forEach((key) => {
    encodeMessage = encodeMessage.replace(key, t(key));
  });
  return encodeMessage;
};

export const LUCKY_NEXT_TIME = 'LUCKY_NEXT_TIME';
export const YOU_SPUN_AND_HIT = 'YOU_SPUN_AND_HIT';
export const CONGRATULATIONS_TO = 'CONGRATULATIONS_TO';
export const FOR_SUCCESSFULLY_SPINNING_AND_WINNING = 'FOR_SUCCESSFULLY_SPINNING_AND_WINNING';
export const LUCKY_WHEEL_NOT_FOUND = 'LUCKY_WHEEL_NOT_FOUND';
export const WRONG_ANSWER = 'WRONG_ANSWER';

export default {
  convertLuckyMessage,
  LUCKY_NEXT_TIME,
  YOU_SPUN_AND_HIT,
  CONGRATULATIONS_TO,
  FOR_SUCCESSFULLY_SPINNING_AND_WINNING,
  LUCKY_WHEEL_NOT_FOUND,
  WRONG_ANSWER
};
