/* eslint-disable react/destructuring-assignment */
import { Avatar, Typography } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import clsx from 'clsx';
import { LinkComp } from 'components/atoms';
import { useSetting } from 'context';
import { gtag } from 'utils';
import { LOGO_THUOCSI_SHORTENED } from 'constants/Images';
import { BRAND_NAME } from 'constants/Enums';
import { useTranslation } from 'next-i18next';
import { ImageFallbackStoreImage } from 'utils/ImageFallback';
import useMobileV2 from 'zustand-lib/storeMobile';
import { ChevronRight } from '@material-ui/icons';
import styles from './styles.module.css';

// const ButtonChatNgay = ({ isMobileV2 = false, translateProductDetail }) => (
//   <LinkComp className={styles.linkShop} href="https://zalo.me/0932036749" target="_blank">
//     <ImageFallbackStatic src={`${ICON_CHAT_NGAY}?size=origin`} className={styles.sellerIcon} width={14} height={16} alt="" />
//     <Typography variant={`${isMobileV2 ? 'body2' : 'body'}`} style={{ marginLeft: 5 }}>
//       {translateProductDetail('chat_now')}
//     </Typography>
//   </LinkComp>
// );

const SellerInfoProductDetail = (props) => {
  const { t: translateCommon } = useTranslation('common');
  const { t: translateProductDetail } = useTranslation('productDetail');
  // gọi tới cache chung lấy seller map
  const { seller = {}, tags = [], flagshipStoreInfo = null, isMobile } = props;
  const { code } = seller;
  const { getNameSeller } = useSetting();
  const { sellerName, linkStore, avatar = [], tcode, fullNameSeller } = getNameSeller({ seller, tags });
  const displayName = tcode !== 'seller' ? translateCommon(`seller.${tcode}`).replace('{BRAND_NAME}', BRAND_NAME) : fullNameSeller;
  // const newSellerName = translateCommon(`seller.${tcode}`) || fullNameSeller;
  const partnerName = translateProductDetail('partner_of_thuocsi')?.replace('{BRAND_NAME}', BRAND_NAME) || '';
  const beta = useMobileV2((state) => state.beta);
  // const [yearNumber, setYearNumber] = useState(null);

  // không show nút chat ngay đối với các seller từ bên supplier
  // các seller đó là seller internal & các seller flagshipstore durex & sanofi
  // const isShowBtnChat = !isInternal && isStore && ENV_COUNTRY === 'vn';

  // yearNumber
  // useEffect(async () => {
  //   // số năm hợp tác với thuocsi -> deprecate
  //   const supplierRes = await SupplierService.getInfoSupplier({});
  //   if (isValid(supplierRes)) {
  //     const yearNumberCur = new Date().getFullYear() - getFirst(supplierRes).yearFounded;
  //     setYearNumber(yearNumberCur);
  //   }
  // }, []);

  if (flagshipStoreInfo) {
    const { primaryColor } = flagshipStoreInfo;

    return (
      <div style={{ position: 'relative', marginTop: 8, backgroundColor: '#fff' }}>
        <span className={styles.flagshipTag} style={{ backgroundColor: primaryColor }}>
          {translateProductDetail('brand_booth')}
        </span>
        <div className={styles.seller_top_info}>
          <div className={styles.avatar_name}>
            <Avatar
              src={flagshipStoreInfo.logo[0]}
              className={clsx(styles.avatar, { [styles.avatar_mv2]: isMobile })}
              style={{ border: `1px solid ${primaryColor}` }}
            />
            <div>
              <Typography className={styles.name}>{flagshipStoreInfo.name}</Typography>
              <Typography className={styles.subName}>{translateProductDetail('supplier')}</Typography>
            </div>
          </div>
          <LinkComp
            className={styles.linkCustomShop}
            href={flagshipStoreInfo.url}
            style={{ border: '1px solid #025BAC' }}
            onClick={() => {
              gtag.clickViewShop(flagshipStoreInfo.name);
            }}
          >
            {translateProductDetail('view_store')}
            <ChevronRight />
          </LinkComp>
        </div>
        <Typography className={styles.subNameBottom}>
          {translateProductDetail('authentic_product_x_distributed', { slug: flagshipStoreInfo.slug.toUpperCase() })}
        </Typography>
      </div>
    );
  }

  if (isMobile) {
    return (
      <>
        {code && sellerName ? (
          <div className={styles.mobile_wrapper} role="presentation" data-test="product-seller">
            <div className={styles.row}>
              <div className={styles.mobile_avatar_name}>
                {sellerName === 'MEDX' ? (
                  <ImageFallbackStoreImage alt="MEDX" src={LOGO_THUOCSI_SHORTENED} className={styles.mobile_avatar} width={18} height={18} />
                ) : (
                  <Avatar src={avatar[0]} className={styles.mobile_avatar} />
                )}
                <Typography className={styles.mobile_name}>{displayName}</Typography>
              </div>
              <LinkComp
                className={styles.visit_store}
                href={linkStore}
                onClick={() => {
                  gtag.clickViewShop(sellerName);
                }}
              >
                <Typography>{translateProductDetail('view_store')}</Typography>
                <ChevronRight width={16} height={16} />
              </LinkComp>
            </div>
            <div className={styles.row}>
              <Typography>{translateProductDetail('rating')}</Typography>
              <Rating name="rating" value={5} readOnly size="medium" className={styles.rating} />
            </div>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }

  return (
    <>
      {code && sellerName && (
        <div className={clsx(styles.wrapper, code && seller && styles.hide_border_top)}>
          <div className={styles.avatar_name}>
            {sellerName === 'MEDX' ? (
              <ImageFallbackStoreImage alt="MEDX" src={LOGO_THUOCSI_SHORTENED} className={styles.sellerIcon} width={48} height={48} />
            ) : (
              <Avatar src={avatar[0]} className={styles.avatar} />
            )}
            <div>
              <Typography className={clsx(styles.name, sellerName === partnerName && styles.text_tf_none)}>{displayName}</Typography>
            </div>
            <Rating name="rating" value={5} readOnly size="medium" className={styles.rating} />
            <LinkComp
              className={styles.visit_store}
              href={linkStore}
              onClick={() => {
                gtag.clickViewShop(sellerName);
              }}
            >
              <Typography>{translateProductDetail('view_store')}</Typography>
              <ChevronRight width={16} height={16} />
            </LinkComp>
          </div>
        </div>
      )}

      {/* <div className={styles.btnLinkShop}>
            {isStore ? (
              <Grid container justifyContent="space-between">
                <Grid item>
                  <LinkComp
                    className={styles.linkShop}
                    href={linkStore}
                    onClick={() => {
                      gtag.clickViewShop(sellerName);
                    }}
                  >
                    <ImageFallbackStatic src={`${STORE_ICON_2}?size=origin`} className={styles.sellerIcon} width={14} height={16} alt="" />
                    <Typography variant={`${isMobileV2 ? 'body2' : 'body'}`} style={{ marginLeft: 5 }}>
                      {translateProductDetail('view_store')}
                    </Typography>
                  </LinkComp>
                </Grid>
                {isShowBtnChat && (
                  <Grid item>
                    <ButtonChatNgay isMobileV2={isMobileV2} translateProductDetail={translateProductDetail} />
                  </Grid>
                )}
              </Grid>
            ) : (
              <>
                {isDisplayStore && (
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <LinkComp
                        className={styles.linkShop}
                        href={linkSeller}
                        onClick={() => {
                          gtag.clickViewShop(sellerName);
                        }}
                      >
                        <ImageFallbackStatic src={`${STORE_ICON_2}?size=origin`} className={styles.sellerIcon} width={14} height={16} alt="" />
                        <Typography variant={`${isMobileV2 ? 'body2' : 'body'}`} style={{ marginLeft: 5 }}>
                          {translateProductDetail('view_store')}
                        </Typography>
                      </LinkComp>
                    </Grid>
                    {isShowBtnChat && (
                      <Grid item>
                        <ButtonChatNgay translateProductDetail={translateProductDetail} />
                      </Grid>
                    )}
                  </Grid>
                )}
              </>
            )}
          </div> */}

      {/* hiện tại không có số này */}
      {/* {!Number.isNaN(yearNumber) && (
        <div className={styles.cooperation}>
          <div className={styles.years}>
            <Typography className="yearsNumber">{yearNumber}+</Typography>
            <Typography style={{ color: '#fff', fontSize: 10, lineHeight: '13px' }}>năm</Typography>
          </div>
          <Typography className={styles.cooperationText}>Hợp tác cùng thuốc sỉ</Typography>
        </div>
      )} */}

      {/* <div className={styles.row}>
        <Typography>Sản phẩm</Typography>
        <Typography>67</Typography>
      </div>
      <div className={styles.row}>
        <Typography>Người theo dõi</Typography>
        <Typography>13k</Typography>
      </div> */}
      {/* <Typography className={sellerName && styles.bottom}>
        Hệ thống sẽ chọn nhà cung cấp tốt nhất cho bạn.
        <a href="/terms-and-condition"> Điều khoản sử dụng</a>
      </Typography> */}
      {/* <Box className={styles.bottom1}> */}
      {/* <Typography>{translateProductDetail('sell_with_thuocsi').replace('{BRAND_NAME}', BRAND_NAME)}</Typography> */}
      {/* only vn */}
      {/* {ENV_COUNTRY === 'vn' && <a href="https://try.thuocsi.vn/banhangcungthuocsi"> {translateProductDetail('register')}</a>} */}
      {/* </Box> */}
    </>
  );
};

export default SellerInfoProductDetail;
