import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { BRAND_NAME } from 'constants/Enums';
import { useTranslation } from 'next-i18next';
import styles from './styles.module.css';

function PasswordWarningText({ className }) {
  const { t: translatePopupAction } = useTranslation('popup-action');
  return (
    <Typography className={clsx(styles.text_warning, className && className)}>
      {translatePopupAction('popup_login.password_security_login').replace('{BRAND_NAME}', BRAND_NAME)}
    </Typography>
  );
}

export default PasswordWarningText;
