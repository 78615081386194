import { Button, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Modal } from 'components/atoms';
import { useTranslation } from 'next-i18next';
import { memo } from 'react';

import styles from './style.module.css';

const EditOrderModal = memo((props) => {
  const { t: translatePopupAction } = useTranslation('popup-action');
  const { onClose, onClickOk, visible, className, restProps } = props;

  return (
    <Modal className={className} open={visible} {...restProps} onClose={onClose}>
      <div className={styles.confirm_modal_wrap}>
        <div>
          <div className={styles.warning_icon}>
            <Typography className={styles.text_icon}>!</Typography>
          </div>
          <Typography className={styles.modal_title}>{translatePopupAction('popup-edit-order.title')}</Typography>
          <Typography className={styles.modal_content}>
            <div dangerouslySetInnerHTML={{ __html: translatePopupAction('popup-edit-order.content') }} />
          </Typography>
        </div>
        <div style={{ marginTop: '16px', textAlign: 'center' }}>
          <Button className={clsx(styles.btn, styles.brown_btn)} onClick={onClose} data-test="btn-cancel-edit-order">
            {translatePopupAction('popup-edit-order.cancel')}
          </Button>
          <Button onClick={onClickOk} className={clsx(styles.btn, styles.fill_btn)} data-test="btn-ok-edit-order">
            {translatePopupAction('popup-edit-order.confirm')}
          </Button>
        </div>
      </div>
    </Modal>
  );
});

export default EditOrderModal;
