import LinkComp from 'components/atoms/LinkComp';
import { getPathProductBySlug } from 'constants/Paths';
import { useTranslation } from 'next-i18next';
import { v4 as uuidv4 } from 'uuid';
import styles from './styles.module.css';

const SearchDropdownFeedback = (props) => {
  const { t: translateCommon } = useTranslation('common');
  const { data, keyword, onClickItem } = props;
  return (
    <div className={styles.searchDropdown}>
      {data ? (
        <>
          {data?.map((item) => (
            <LinkComp
              className={styles.searchResults}
              key={uuidv4()}
              href={getPathProductBySlug(item.sku.slug)}
              onClick={(e) => {
                e.preventDefault();
                if (onClickItem) onClickItem(item);
              }}
            >
              {item?.product?.name}
            </LinkComp>
          ))}
        </>
      ) : (
        <span className={styles.searchResults}>
          {translateCommon('nav_bar.no_product')} <em>{keyword}</em>&nbsp;&nbsp;{translateCommon('nav_bar.in')}&nbsp;
          <b className={styles.textPrimary}>{translateCommon('all_products')}</b>
        </span>
      )}
    </div>
  );
};

export default SearchDropdownFeedback;
