import { Button, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Modal } from 'components/atoms';
import { memo } from 'react';

import { useTranslation } from 'next-i18next';
import styles from './style.module.css';

const DeleteAddressModal = memo((props) => {
  const { onClose, onClickOk, visible, className, restProps } = props;

  const { t: translatePopupAction } = useTranslation('popup-action');

  return (
    <Modal className={className} open={visible} {...restProps} onClose={onClose}>
      <div className={styles.confirm_modal_wrap} data-test="modal-delete-address">
        <div>
          <div className={styles.warning_icon}>
            <Typography className={styles.text_icon}>!</Typography>
          </div>
          <Typography className={styles.modal_title}>{translatePopupAction('popup-removeAddress.title')}</Typography>
          <Typography className={styles.modal_content}>{translatePopupAction('popup-removeAddress.content')}</Typography>
        </div>
        <div style={{ marginTop: '16px', textAlign: 'center' }}>
          <Button className={clsx(styles.btn, styles.brown_btn)} onClick={onClose}>
            {translatePopupAction('popup-removeAddress.no')}
          </Button>
          <Button onClick={onClickOk} className={clsx(styles.btn, styles.fill_btn)} data-test="btn-ok-delete-modal">
            {translatePopupAction('popup-removeAddress.yes')}
          </Button>
        </div>
      </div>
    </Modal>
  );
});

export default DeleteAddressModal;
