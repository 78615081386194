import { ValidateUtils } from 'utils';
import { trimText } from 'utils/StringUtils';

const validateForm = ({ companyName, companyAddress, taxCode, mst, translateAlertAction }) => {
  if (ValidateUtils.isEmpty(trimText(companyName))) {
    const companyNameElement = document.getElementById('companyName');
    if (companyNameElement) {
      companyNameElement.focus();
    }
    throw Error(translateAlertAction('message_empty_company_name'));
  }
  if (trimText(companyName).length <= 2) throw Error(translateAlertAction('message_valid_company_name'));
  if (ValidateUtils.isEmpty(taxCode || mst)) {
    const taxCodeElement = document.getElementById('taxCode');
    if (taxCodeElement) {
      taxCodeElement.focus();
    }

    throw Error(translateAlertAction('message_empty_tax_code'));
  }
  if (ValidateUtils.isEmpty(trimText(companyAddress))) {
    const companyAddressElement = document.getElementById('companyAddress');
    if (companyAddressElement) {
      companyAddressElement.focus();
    }
    throw Error(translateAlertAction('message_empty_company_addresss'));
  }
  if (trimText(companyAddress).length <= 2) throw Error(translateAlertAction('message_invalid_company_address'));

  // if (!ValidateUtils.isNumber(mst)) throw Error('Mã số thuế sai định dạng');
  const mstError = ValidateUtils.validateMst(taxCode || mst) || '';
  if (mstError) throw Error(translateAlertAction(`${mstError}`));

  // Truong Phan confirm
  // if (mst.length !== 10 && mst.length !== 14) throw Error('Mã số thuế phải có 10 hoặc 14 số');
};

export default validateForm;
